<template>
  <div class="modal-quick-access">
    <dass-dialog
      v-if="is_modal_open"
      :title="$t('login.modals.quickAccess.titulo')"
      size="medium"
      ref="ModalQuickAccess"
      @close="toogleModal(false)"
    >
      <template #content>
        <div class="subtitulo">
        </div>
        <form action="" @submit="formSubmit" ref="form">
          <div class="items-group">
            <div class="checkbox-group">
              <div class="checkbox-item">
                <input 
                  type="checkbox" 
                  id="show-email" 
                  :class="`dass-modal-checkbox-${this.brandName}`"
                  v-model="showEmail" 
                  @change="toggleField('email')" 
                />
                <label for="show-email" class="items-checkbox">{{ $t('login.modals.quickAccess.receberCodEmail') }}</label>
              </div>
              <dass-icon class="checkbox-icon" :class="brand.class_name || ''" token="icon-communication-email" size="20" />
            </div>
            <div class="input-email" v-if="showEmail">
              <dass-input-text
                type="email"
                id="input-email"
                v-model="email"     
                :required="showEmail"                
              />
              <dass-button
                :id="`dass-button-call-code-${this.brandName}`"
                class="dass-button-call-code"
                :class="this.isSafari ? `dass-button-primary-${this.brandName}` : ''"
                variant="primary"
                type="submit"
              >
              {{ $t('dass.enviar') }}
            </dass-button>
            </div>
            <div class="checkbox-group">
              <div class="checkbox-item">
                <input 
                  type="checkbox" 
                  id="show-phone" 
                  :class="`dass-modal-checkbox-${this.brandName}`"
                  v-model="showPhone"                   
                  @change="toggleField('phone')"                 
                />
                <label for="show-phone" class="items-checkbox">{{ $t('login.modals.quickAccess.receberCodPhone') }}</label>
              </div>
              <dass-icon class="checkbox-icon" :class="brand.class_name || ''" token="icon-socialmedia-whatsapp" size="20" />
            </div>
            <div class="input-phone" v-if="showPhone">
              <dass-input-text
                type="phone"
                id="input-phone"
                v-model="phone"   
                placeholder="+55 (11) 99999-9999"
                v-mask="['+## (##) #####-####']"
                :required="showPhone"                
              />
              <dass-button
                :id="`dass-button-call-code-${this.brandName}`"
                class="dass-button-call-code"
                :class="this.isSafari ? `dass-button-primary-${this.brandName}` : ''"
                variant="primary"
                type="submit"
              >
              {{ $t('dass.enviar') }}
            </dass-button>
            </div>
          </div>
          <hr class="divider" />
          <div class="terms-container">
            <div class="checkbox-container">
              <input
                id="termsCheckbox"
                :class="`dass-modal-checkbox-${this.brandName}`"
                type="checkbox"
                v-model="checkboxValue"
              />
              <div class="custom-checkbox" :class="brand.class_name || ''" @click="checkboxValue = !checkboxValue"></div>
              </div>
              <small>
              <label for="termsCheckbox" class="terms-checkbox">
                {{ $t('login.estouCiente') }}
                <dass-link :to="this.brand.police_link" target="_blank">
                {{ $t('login.TermsAndConditions') }}
                </dass-link>
              </label>
            </small>
          </div>
          <small v-if="isWarningVisible">
            <div class="warning-text">
              <dass-icon class="warning-icon" token="icon-alert-error-outline" color="red" size="20px" />
              <div>
                Por favor, confirme os termos para prosseguir
              </div>
            </div>
          </small>
          <div class="">
            <small>
              <dass-link
                @click="
                  () => {
                    toogleModal(false)
                    toogleModalCode(true)
                  }
                "
              >
                {{ $t('login.jaTenhoCodigo') }}
              </dass-link>
            </small>
          </div>          
        </form>
      </template>
    </dass-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { REGEX_EMAIL } from '../../../helpers/constants/regex'
import { useIsSafari } from '@/composables/useIsSafari'
import { validatorPhoneWithDDI } from '@/helpers/functions/telefoneValidator'

export default {
  name: 'ModalQuickAccess',
  props: {},
  computed: {
    ...mapGetters('modals', {
      is_modal_open: 'getModalQuickAccessStatus',
    }),
    ...mapGetters('brand', { brand: 'getBrand' }),
  },
  setup() {
    const { isSafari, brandName } = useIsSafari()
  
    return {      
      isSafari,
      brandName
    }     
  },
  methods: {
    ...mapActions('modals', {
      toogleModal: 'toogleModalQuickAccess',
      toogleModalCode: 'toogleModalVerifyCode',
    }),
    ...mapActions('toasts', {
      showToast: 'showToast',
    }),
    ...mapActions('session', ['sendEmailCode']),

    cancelForm: function () {
      this.$refs.ModalQuickAccess.close()
    },

    formSubmit: async function (e) {
      e.preventDefault()
      if(!this.checkboxValue) return this.isWarningVisible = true
      const email = this.email
      const phone = this.phone.replace(/\D/g, '')
      if ((email === '' || !email.match(REGEX_EMAIL)) && this.showEmail) {
        return this.showToast({
          variant: 'warning',
          message: this.$t('login.modals.quickAccess.erro'),
        })
      }
      if (!validatorPhoneWithDDI(phone) && this.showPhone) {
        return this.showToast({
          variant: 'warning',
          message: this.$t('login.modals.quickAccess.errophone'),
        })
      }

      const response_login = await this.sendEmailCode({email: this.showEmail ? email : null, phone: this.showPhone ? phone: null})
      const message = response_login.message
      this.showToast({
        variant: 'success',
        message,
      })

      localStorage.setItem(`terms_and_conditions_${this.brand.police_link}`, true)

      this.$refs.ModalQuickAccess.close()
      this.toogleModalCode(true)
    },
    toggleField(selected) {
      if (selected === 'email') {
        this.showPhone = false 
        this.phone = ''
      } else if (selected === 'phone') {
        this.showEmail = false 
        this.email = ''
      }
    }
  },
  mounted() {
    const initialCheckbox = localStorage.getItem(`terms_and_conditions_${this.brand.police_link}`)
    if(initialCheckbox) this.checkboxValue = initialCheckbox
  },
  watch: {
    checkboxValue() {
      if(this.checkboxValue) this.isWarningVisible = false
    }
  },  
  data: () => ({
    email: '',
    checkboxValue: false,
    isWarningVisible: false,
    showPhone: false,
    showEmail: false
  }),
}
</script>

<style lang="scss">
.modal-quick-access {
  .dass-dialog--contents {
    padding-top: 0;
  }
  .subtitulo {
    padding-bottom: var(--space);
  }
  label {
    font-weight: bold;
  }
  .items-checkbox {
    font-size: 1rem;
  }

  .footer {
    margin-top: var(--space__1-5x);
    display: flex;
    justify-content: space-between;
    .dass-button__primary {
      width: 250px;
    }
    .dass-button__tertiary {
      width: 250px;
    }
    .dass-button-tertiary-newbalance {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #E5788B !important;
      }
    }
    .dass-button-primary-newbalance {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #E5788B !important;
      }
    }
    .dass-button-tertiary-umbro {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #737373 !important;
      }
    }
    .dass-button-primary-umbro {
      transition: border 0.3s ease !important;
      &:hover {
        border: 2px solid #737373 !important;
      }
    }
    .dass-button-tertiary-fila {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #758396 !important;
      }
    }
    .dass-button-primary-fila {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #758396 !important;
      } 
    }
  } 
  
}

.divider{
  margin-bottom: 10px;
}

.items-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
  gap: 5px;
}

.items-group .dass-input-text {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.checkbox-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.theme-umbro {
  .dass-dialog--control {
    background-color: var(--theme-color);
    color: var(--theme-white);
  }
  .dass-link {
    outline: none !important;
    color: var(--theme-white) !important;
  }
  .dass-button__tertiary {
    color: var(--theme-white) !important;
    text-decoration: underline !important;
  }
  .dass-button__primary {
    background-color: var(--theme-white) !important;
    color: var(--theme-color) !important;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #758396;
    }
  }
}
.terms-container {
  display: flex;
  align-items: center;
  @media (max-width: 415px) {
    align-items: flex-start;
  }
}
.checkbox-container {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .custom-checkbox {
    width: 20px;
    height: 20px;
    display: flex;
    content: none;
    border-radius: var(--theme-border-radius);
    align-items: center;
    justify-content: center;
    transition: background 0.3s;
    background: none;
    margin-right: 7px;
  }
  .umbro {
    border: 2px solid var(--theme-white);
  }
  .fila {
    border: 2px solid #758396;
  }
  .new_balance {
    border: 2px solid #E5788B;
  }
  .custom-checkbox::after {
    content: "";
    display: none;
    width: 10px;
    height: 5px;
    transform: rotate(-45deg);
    position: relative;
  }
  .umbro::after {
    border-left: 2.5px solid var(--theme-color);
    border-bottom: 2.5px solid var(--theme-color);
  }
  .fila::after {
    border-left: 2.5px solid var(--theme-white);
    border-bottom: 2.5px solid var(--theme-white);
  }
  .new_balance::after {
    border-left: 2.5px solid var(--theme-white);
    border-bottom: 2.5px solid var(--theme-white);
  }

  input[type="checkbox"]:checked + .custom-checkbox.umbro {
    background-color: var(--theme-white);
  }

  input[type="checkbox"]:checked + .custom-checkbox.fila {
    background-color: #758396;
  }

  input[type="checkbox"]:checked + .custom-checkbox.new_balance {
    background-color: #E5788B;
  }

  input[type="checkbox"]:checked + .custom-checkbox::after {
    color: var(--theme-color);
  }
  input[type="checkbox"]:checked + .custom-checkbox::after {
    display: block;
  }
}

.warning-text {
  display: flex;
  flex-direction: row;
  color: red;
  align-items: center;
  font-size: smaller;
}

.warning-icon {
  margin-right: 7px;
}

.dass-input-text input {
  outline: none !important;
}

.checkbox-icon {
  margin-right: 40%;
  @media (max-width: 768px) { 
    margin-right: 15%;
  }
}

.checkbox-icon.umbro {
  color: var(--theme-white)
}

.checkbox-icon.fila {
  color: #758396
}

.checkbox-icon.new_balance {
  color: #E5788B
}
.input-phone, .input-email {
  display: flex;
  justify-content: center;
}
#input-email, #input-phone {
  width: 100% !important;
  padding-right: 15% !important;
}
.dass-button-call-code {
  position: relative;
  top: 14.2px;
  right: 12%;
  width: 40%;
  height: 100%; 
  padding: 10px 20px 10px 20px !important;  
}
#dass-button-call-code-umbro {
  background-color: var(--background-color-button-primary-shopping-voucher) !important;
}

.dass-modal-checkbox-umbro:checked + .custom-checkbox {
  background-color: var(--background-color-button-primary-shopping-voucher) !important;
}
.dass-modal-checkbox-fila:checked + .custom-checkbox {
  background-color: var(--background-color-button-primary-shopping-voucher) !important;
}
.dass-modal-checkbox-newbalance:checked + .custom-checkbox {
  background-color: var(--color-primary) !important;
}

</style>
