import * as types from './mutation-types'

export default {
  [types.FILL_PROTOCOL](state, payload = { protocol }) {
    state.protocol = payload.protocol
  },
  [types.FILL_HISTORY](state, payload = { history }) {
    state.history = payload.history
  },
  [types.FILL_PROTOCOL_ITEMS_DATA](state, payload = { protocolItems }) {    
    state.protocolItems = payload.protocolItems    
  },
}
