export const MOTIVOS = Object.freeze({
  16: 'O tamanho que comprei não ficou bom',
  TROCA_TAMANHO: 16,
  TROCA_COR: 17,  
})
export const DESEJOS = Object.freeze({
  1: 'Receber o mesmo produto no tamanho ...',
  MESMO_PRODUTO: 1,
  ESTORNO: [3, 5, 7, 10, 13, 15],
  MESMO_PRODUTO_TROCA_COR: [2, 4, 6, 8, 9, 11, 12],
})
