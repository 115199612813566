import { watch, ref, computed } from 'vue'
import { useStore } from 'vuex'
const KEY = 'UNIQUE_KEY'
let selectedOrders = ref({})
export function useSelectedOrderFromLocalStorage() {
  const store = useStore()
  const getEmailUsu = store.getters['session/getUsuEmail'] || ''
  const STORAGE_KEY = `selected_orders_${getEmailUsu}`
  const storedValue = localStorage.getItem(STORAGE_KEY)
  selectedOrders.value = (storedValue ? JSON.parse(storedValue) : {})   

  watch(selectedOrders, (newValue) => {    
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newValue))        
  }, { deep: true })
  
  const selectOrder = (order) => {
    if (!order.hasOwnProperty(KEY)) return
    const ORDER_KEY = order[KEY] || ''
    selectedOrders.value[ORDER_KEY] = order     
  }

  const unselectOrder = (order) => {    
    if (!order.hasOwnProperty(KEY)) return
    const ORDER_KEY = order[KEY] || ''
    if (!selectedOrders.value.hasOwnProperty(ORDER_KEY)) return
    delete selectedOrders.value[ORDER_KEY]          
  }

  const getSelectedOrders = computed(() => {
    return {...selectedOrders.value}
  })  
  
  const clearSelectedOrders = () => {
    selectedOrders.value = {}  
    selectedOrders.value = {...selectedOrders.value}
  }
  
  return {   
    getSelectedOrders,     
    selectOrder,
    unselectOrder,
    clearSelectedOrders,
  }
}