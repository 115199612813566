export const getSubjects = (state) => state.data.subjects
export const getReasons = (state) => state.data.reasons
export const getDadosGerais = (state) => state.dadosGerais
export const getDadosItens = (state) => state.dadosItens
export const getBankData = (state) => state.bankData
export const getSubReasons = (state) => state.data.subReasons
export const getAllSubReasons = (state) => state.data.allSubReasons
export const getCurrentStep = (state) => state.current_step
export const getPictureList = (state) => state.picture_list
export const getItensToAddBankData = (state) => state.itensToAddBankData
export const getDataConsumer = (state) => state.dataConsumer
export const getBanks = (state) => state.banks