<template>       
        <div class="buyer-data">
          <form v-show="editMode" :style="!isPaymentReversal ? 'column-gap: var(--space__3x)' : ''" @submit.prevent="formSubmit">
            <div class="dass-form-data-client">
              <h3>{{ $t('etapas.comprador.dataClient') }}</h3>
              <div>
                <dass-input-text
                  autofocus
                  :required="true"
                  :disabled="disabled_fields.includes('cpf') || this.disabledFieldWhileGetData"
                  type="tel"
                  id="dass-input-cpf"
                  v-model="form_data.buyer.cpf"
                  v-mask="'###.###.###-##'"
                  :label="$t('etapas.comprador.cpf') + ':'"
                  @blur="validateCpf"
                />
                <small v-if="invalidCpf">{{ this.$t('dass.invalidCpf') }}</small>      
                <dass-input-text
                  :required="true"
                  :disabled="disabled_fields.includes('nome') || this.disabledFieldWhileGetData"
                  type="text"
                  v-model="form_data.buyer.nome"
                  :label="$t('etapas.comprador.nome') + ':'"
                />
              
                <dass-input-text
                  :required="true"
                  :disabled="disabled_fields.includes('email') || this.disabledFieldWhileGetData"
                  type="email"
                  v-model="form_data.buyer.email"
                  :label="$t('etapas.comprador.email') + ':'"
                  @blur="validateEmail"
                />
                <small v-if="invalidEmail"> {{ this.$t('dass.invalidEmail') }}</small>
                <dass-input-text
                  :required="true"
                  type="tel"
                  :disabled="disabled_fields.includes('telefone') || this.disabledFieldWhileGetData"
                  v-model="form_data.buyer.telefone"                  
                  placeholder="+55 (11) 99000-0000"
                  v-mask="['+## (##) ####-####', '+## (##) #####-####']"
                  :label="$t('etapas.comprador.telefone') + ':'"
                  @blur="validatePhoneNumber"                  
                />
                <small v-if="invalidPhoneNumber"> {{ this.$t('dass.phoneInvalid') }}</small>
              </div>
            </div>
            <div v-if="isPaymentReversal" class="dass-form-data-bank">          
              <h3>{{ $t('dass.bankData') }}</h3>
              <div>
                <dass-input-text
                    :required="true"
                    type="tel"
                    id="dass-input-agencia"
                    :disabled="this.disabled_fields.includes('cli_agencia')"
                    placeholder="0000-7"              
                    :label="$t('etapas.comprador.agencia') + ':'"                  
                    v-model="form_data.bank.cli_agencia"                    
                    @blur="validateAgencia()"
                />
                <small v-if="invalidAgenciaField">{{ this.$t('dass.invalidAgency') }}</small>
                <dass-input-text
                    :required="true"
                    type="tel"
                    id="dass-input-conta"
                    :disabled="this.disabled_fields.includes('cli_conta')"
                    :label="$t('etapas.comprador.conta') + ':'"
                    placeholder="0000-7"
                    v-model="this.form_data.bank.cli_conta"
                    @blur="validateConta()"
                  /> 
                  <small v-if="invalidContaField">{{ this.$t('dass.invalidAccount') }}</small> 
                <dass-select
                  name="select-bank"
                  :label="$t('etapas.comprador.codigoBanco') + ':'"
                  :required="true"
                  :options="optionsBanks"                
                  id="dass-select-bank"
                  :disabled="this.disabled_fields.includes('ban_codigo')"
                  @change="
                    (val) => {
                      if (typeof val == 'object') return
                      form_data.bank.ban_codigo = val                                       
                    }
                  "
                >
                </dass-select>
                 <dass-input-text
                    :required="true"
                    id="dass-input-pix"  
                    :disabled="this.disabled_fields.includes('cli_chavepix')"            
                    type="text"                        
                    :label="$t('etapas.comprador.chavePix') + ':'"
                    :modelValue="this.pixKeyFormated"
                    v-model="this.form_data.bank.cli_chavepix"
                    placeholder="CPF,CNPJ,Email ou Telefone"
                    @update:modelValue="maskedPix"
                    @blur="validatePix"                                    
                  /> 
                  <small v-if="invalidPixField">{{ this.$t('dass.invalidPix') }}</small>
              </div>
            </div>  
            <div class="dass-form-data-delivery" :class="{ 'is-payment-reversal': isPaymentReversal }">          
              <h3>{{ $t('etapas.comprador.addressClient') }}</h3>
              <div>
                <dass-input-text
                  :required="true"
                  type="tel"
                  id="dass-input-cep"
                  v-model="form_data.address.cep"
                  :disabled="this.disabledFieldWhileGetData || this.disabled_fields.includes('cep')"
                  v-mask="'#####-###'"
                  :label="$t('etapas.comprador.cep') + ':'"
                  @blur="loadDataCep"
                />
                <small v-if="invalidCep">{{ this.$t('dass.invalidCep') }}</small>
                <dass-input-text
                  :required="true"
                  type="text"
                  :disabled="this.disabledFieldWhileGetData || this.disabled_fields.includes('endereco')"
                  v-model="form_data.address.endereco"
                  :label="$t('etapas.comprador.endereco') + ':'"
                />
                <dass-input-text
                  :required="true"
                  type="text"
                  :disabled="this.disabledFieldWhileGetData || this.disabled_fields.includes('numero')"
                  v-model="form_data.address.numero"
                  :label="$t('etapas.comprador.numero') + ':'"
                />
                <dass-input-text
                  :required="true"
                  type="text"
                  v-model="form_data.address.uf"
                  v-mask="'AA'"
                  :label="$t('etapas.comprador.uf') + ':'"
                  :disabled="this.disabledUfField || this.disabled_fields.includes('uf')"
                />
              </div>
              <div>
                <dass-input-text
                  :required="true"
                  type="text"
                  :disabled="this.disabledFieldWhileGetData || this.disabled_fields.includes('bairro')"
                  v-model="form_data.address.bairro"
                  :label="$t('etapas.comprador.bairro') + ':'"
                />
                <dass-input-text
                  :required="true"
                  type="text"
                  v-model="form_data.address.cidade"
                  :label="$t('etapas.comprador.cidade') + ':'"
                  :disabled="this.disabledCityField || this.disabled_fields.includes('cidade')"
                />
                <dass-input-text
                  type="text"
                  :disabled="this.disabledFieldWhileGetData || this.disabled_fields.includes('complemento')"
                  v-model="form_data.address.complemento"
                  :label="$t('etapas.comprador.complemento') + ':'"
                />
                <div class="actions">   
                <dass-button id="dass-button-confirm-form-buyer-data" v-if="editMode" :class="this.isSafari ? `dass-button-primary-${this.brandName} button` : 'button'" variant="primary" type="button" @click="confirmEdit">
                  {{ $t('etapas.comprador.confirmar') }}
                  <dass-icon
                    token="icon-navigation-arrow_forward_ios"
                    size="30"
                  ></dass-icon>
                </dass-button>                
                <dass-button id="dass-button-confirm-form-buyer-data" v-show="!hideButtonConfirm" :class="this.isSafari ? `dass-button-primary-${this.brandName} button` : 'button'" variant="primary" type="submit">
                  {{ $t('etapas.comprador.confirmar') }}
                  <dass-icon
                    token="icon-navigation-arrow_forward_ios"
                    size="30"
                  ></dass-icon>
                </dass-button>                                
                </div>
              </div>          
            </div>
          </form>
          <ResumedData 
           v-if="verifyIfShowResumedData()"
           :form_data="form_data"
           :isPaymentReversal="isPaymentReversal"
           @editFormEmit="editForm()"
          />
          <hr>
        </div>
  </template>
<script>
import { VMoney } from 'v-money'
import { moneyMask } from '@/helpers/globalfilters/defaultMoneyMask'
import { mapGetters, mapActions } from 'vuex'
import { cpfValidator } from '@/helpers/functions/cpfValidator'
import { emailValidator } from '@/helpers/functions/emailValidator'
import { useIsSafari } from '@/composables/useIsSafari'
import { pixValidator } from '@/helpers/functions/pixValidator'
import { agenciaValidator } from '@/helpers/functions/agenciaValidator'
import { contaValidator } from '@/helpers/functions/contaValidator'
import { formatOptionsBankToSelect } from '@/helpers/functions/formatOptionsBankToSelect'
import { maskPix } from '@/helpers/functions/maskPix'
import { autoSelectedItemInList } from '@/helpers/functions/autoSelectedItemInList'
import { areObjectsEqual } from '@/helpers/functions/areObjectsEqual'
import { parsePhoneNumber } from '@/helpers/functions/parsePhoneNumbers'
import { validatorPhoneWithDDI } from '@/helpers/functions/telefoneValidator'
import ResumedData from './ResumedData.vue'
 
const propertiesRequired = [
  'buyer.nome',
  'buyer.telefone',
  'buyer.cpf',
  'buyer.email',
  'address.endereco',
  'address.numero',
  'address.bairro',
  'address.cidade',
  'address.uf',
  'address.cep',
]
export default {
  name: 'BuyerData',
  props: {
    ecommerce: Boolean,
  },
  emits: ['editModeAction'],
  components: { ResumedData },
  directives: { money: VMoney },
  setup() {
    const { isSafari, brandName } = useIsSafari()
    
    return {      
      isSafari,
      brandName
    }     
  },
  data: () => ({
    moneyMask,    
    disabledFieldWhileGetData: false,
    disabled_fields: [],
    disabledUfField: true,
    disabledCityField: true,
    showBuyer: false,
    isPaymentReversal: false,
    pixKeyFormated: '',
    invalidPixField: false,
    invalidAgenciaField: false,
    invalidContaField: false,
    invalidPhoneNumber: false,
    invalidCpf: false,
    invalidEmail: false,
    invalidCep: false,
    optionsBanks: [],
    hideButtonConfirm: true,
    editMode: false,
    form_data: {
      buyer: {
        cpf: '',
        nome: '',
        telefone: '',
        email: '',
      },
      address: {
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        uf: '',
      },
      bank: {
        cli_agencia: '',
        cli_conta: '',
        ban_codigo: '',
        cli_chavepix: ''        
      }
    },
    addressFromOrder: {},
    cli_enddes: '',
    cli_endseqdes: '',
    MOTIVOS: {
      A_PECA_QUE_RECEBI_NAO_FOI_A_QUE_COMPREI: 19,
      O_PRODUTO_CHEGOU_COM_DEFEITO: 20,
    },
  }),
  computed: {
    ...mapGetters('create_protocol', {
      dados_item: 'getDadosItens',
      reasonsList: 'getSubjects',
      bankData: 'getBankData',
      getDataConsumer: 'getDataConsumer',
      getBanks: 'getBanks',
    }),
    ...mapGetters('orders', {
      orders: 'getOrders',
    }),
    ...mapGetters('session', ['getUsuEmail', 'getUser', 'getUserAddress']),
    ...mapGetters('brand', { marca: 'getBrand' }),    
  },
  methods: {
    ...mapActions('create_protocol', [
      'setCurrentStep',
      'createDados',
      'setPictureList',
      'setItensToAddBankData', 
      'loadBankData'     
    ]),
    ...mapActions('toasts', {
      showToast: 'showToast',
    }),
    ...mapActions('addressBook', { getDataCeps: 'getDataCeps' }),
    ...mapActions('session', ['handlerUser']),
    formSubmit: function () {      
      const isValid = this.validateDataRequired()      
      if (!isValid || !this.validateBankAndPhone())  {
        this.$ShowAlert({
          title: 'Atenção',
          message: this.$t('erro.fieldsRequired'),
          type: 'error',
          hideDelay: 2500
        })               
        return
      }
      let origem = 'sac'
        
      if (
        this.dados_item.hasOwnProperty('ecommerce') ||
           (Array.isArray(this.dados_item) &&
             this.dados_item[0].hasOwnProperty('ecommerce'))
      ) {
        origem = 'ecom'
      }      
      const phoneFormated = parsePhoneNumber(this.form_data.buyer.telefone)      
      const cepFormated = this.form_data.address.cep

      let dados = {
        origem,
        marcaCodigo: parseInt(this.marca.id),
        nomeCliente: this.form_data.buyer.nome,
        cpfCliente: this.form_data.buyer.cpf.replace(/[^0-9]+/g, ''),
        emailCliente: this.form_data.buyer.email,
        telefoneCliente: phoneFormated.phone,
        dddCliente: phoneFormated.ddd,
        ddiCliente: phoneFormated.ddi,
        cepCliente: cepFormated.replace(/[^0-9]+/g, ''),
        enderecoCliente: this.form_data.address.endereco,
        numeroEndrCliente: this.form_data.address.numero,
        bairroCliente: this.form_data.address.bairro,
        cidadeCliente: this.form_data.address.cidade,
        UFCliente: this.form_data.address.uf,
        tipo_contato: Array.isArray(this.dados_item) ? this.dados_item[0].tipo_contato : this.dados_item.hasOwnProperty('tipo_contato') ? this.dados_item.tipo_contato : '',
        complementoEndrCliente: this.form_data.address.complemento,        
      }     
      if(Object.keys(this.addressFromOrder).length) {
        const form = {...this.form_data.address}
        form.cep = this.form_data.address.cep.replace(/[^0-9]+/g, '')        
        if (areObjectsEqual(this.addressFromOrder, form)) {
          dados.cli_enddes = this.cli_enddes
          dados.cli_endseqdes = this.cli_endseqdes
        } else {
          dados.cli_enddes ? delete dados.cli_enddes : ''
          dados.cli_endseqdes ? delete dados.cli_endseqdes : ''         
        }
      }       
      this.createDados(dados) 
      this.handlerDisableField()  
    },  
    verifyIfShowResumedData() {      
      if((!this.validateDataRequired() || !this.validateBankAndPhone()) && !this.editMode) {        
        this.editForm()      
        return false
      } 
      if(this.validateDataRequired() && this.validateBankAndPhone() && !this.editMode) {            
        return true        
      }
    }, 
    cancel: function () {
      if (this.ecommerce) {
        return this.$router.push({
          path: '/home',
        })
      }
      this.$router.go(-1)
    },    
    maskedPix() {      
      if(this.form_data.bank.cli_chavepix) {
        this.pixKeyFormated = maskPix(this.form_data.bank.cli_chavepix)
      }
    },   
    validatePix() {   
      if(!pixValidator(this.form_data.bank.cli_chavepix)) {   
        this.invalidPixField = true
      } else {
        this.invalidPixField = false      
      }
      return !this.invalidPixField
    },
    validateBankAndPhone() {
      if(this.isPaymentReversal) {
        if(!this.validatePix() || !this.validateAgencia() || !this.validateConta() || !this.validatePhoneNumber()) {
          return false
        }
      } else if (!this.validatePhoneNumber()) {
        return false
      }
      return true
    },
    validateAgencia() {       
      if(!agenciaValidator(this.form_data.bank.cli_agencia)) {
        this.invalidAgenciaField = true
      } else {
        this.invalidAgenciaField = false
      }
      return !this.invalidAgenciaField
    },
    validateConta() {
      if(!contaValidator(this.form_data.bank.cli_conta)) {
        this.invalidContaField = true
      } else {
        this.invalidContaField = false        
      }
      return !this.invalidContaField
    },
    validatePhoneNumber() {      
      if(!validatorPhoneWithDDI(this.form_data.buyer.telefone)) {
        this.invalidPhoneNumber = true
        return false
      } else {
        this.invalidPhoneNumber = false   
        return true   
      }  
       
    },
    fillForm: async function () { 
      const items = Array.isArray(this.dados_item) ? this.dados_item : [this.dados_item]
      this.disabledFieldWhileGetData = true
      const session = await this.handlerUser()
      
      if (session.hasOwnProperty('user') && session.hasOwnProperty('address') && this.orders.length == 0) {
        let user_data = session.user || {}
        let address_data = session.address || {} 
        this.form_data.buyer = Object.assign({}, this.form_data.buyer, user_data) 
        this.form_data.address = Object.assign({}, this.form_data.address, address_data)           
        if(this.form_data.address.cep && !(this.form_data.address.cidade || this.form_data.address.uf)) {         
          this.loadDataCep()
        } 
      } else {
        this.loadDataClientFromOrder(items)
      }
      this.loadBankDataClient()      
      this.disabledFieldWhileGetData = false
    },
    loadDataClientFromOrder(items) { 
      if(items.length && items[0].ecommerce) {
        const getItemWithDateMoreRecent = items.reduce((acc, item) => {
          const dateAcc = acc.dataCompra.split('/').reverse().join('/')
          const dateItem = item.dataCompra.split('/').reverse().join('/')
          return dateItem > dateAcc ? item : acc
        }, items[0])     
         
        const order = this.orders.find(order => order.ITE_SEQUENCIA == getItemWithDateMoreRecent.itemFatSequencia && order.FAT_SEQUENCIA == getItemWithDateMoreRecent.sequenciaFat && order.NUMERONF == getItemWithDateMoreRecent.numeroNF && order.PRO_CODIGO == getItemWithDateMoreRecent.produtoCodigo)
        
        if(typeof order == 'object' &&  order && Object.keys(order).length > 0) {
          this.form_data.buyer.cpf = order.CPF || ''
          this.form_data.buyer.nome = order.CLI_NOME || '' 
          this.form_data.buyer.email = order.EMAIL || ''
          this.form_data.buyer.telefone = order.CLI_DDI + order.CLI_DDD + order.CLI_FONE  || ''
          this.form_data.address.cep = order.CLI_CEP || ''
          this.form_data.address.endereco = order.CLI_ENDERECO || ''
          this.form_data.address.cidade = order.CLI_CIDADE || ''
          this.form_data.address.uf = order.CLI_UF || ''
          this.form_data.address.numero = order.CLI_ENDNUM || ''
          this.form_data.address.complemento = order.CLI_COMPL || ''
          this.form_data.address.bairro = order.CLI_BAIRRO || ''
          this.addressFromOrder = {...this.form_data.address}   
          this.cli_enddes = order.CLI_ENDDES || ''
          this.cli_endseqdes = order.CLI_ENDSEQDES || ''
        }
      } else if(this.orders.length > 0) {
        const getItemWithDateMoreRecent = this.orders.reduce((acc, item) => {
          const dateAcc = acc.DATA.split('/').reverse().join('/')
          const dateItem = item.DATA.split('/').reverse().join('/')
          return dateItem > dateAcc ? item : acc
        }, this.orders[0])
        const order = getItemWithDateMoreRecent
        this.form_data.buyer.cpf = order.CPF || ''
        this.form_data.buyer.nome = order.CLI_NOME || '' 
        this.form_data.buyer.email = order.EMAIL || ''
        this.form_data.buyer.telefone =  order.CLI_DDI + order.CLI_DDD + order.CLI_FONE || ''
        this.form_data.address.cep = order.CLI_CEP || ''
        this.form_data.address.endereco = order.CLI_ENDERECO || ''
        this.form_data.address.cidade = order.CLI_CIDADE || ''
        this.form_data.address.uf = order.CLI_UF || ''
        this.form_data.address.numero = order.CLI_ENDNUM || ''
        this.form_data.address.complemento = order.CLI_COMPL || ''
        this.form_data.address.bairro = order.CLI_BAIRRO || ''
        this.addressFromOrder = {...this.form_data.address} 
        this.cli_enddes = order.CLI_ENDDES || ''
        this.cli_endseqdes = order.CLI_ENDSEQDES || ''        
      }
    },
    async loadBankDataOptions() {
      if(!this.getBanks || this.getBanks.length == 0) {       
        await this.loadBankData()
        if(this.getBanks.length > 0) {          
          this.optionsBanks = formatOptionsBankToSelect(this.getBanks, this.$t('dass.selectAnItem'))
        } 
      } else {        
        this.optionsBanks = formatOptionsBankToSelect(this.getBanks, this.$t('dass.selectAnItem'))
      }      
    },
    async loadBankDataClient() {
      const items = Array.isArray(this.dados_item) ? this.dados_item : [this.dados_item]     
      if(items.some(item => item.isPaymentReversal)) {
        await this.loadBankDataOptions()        
        this.isPaymentReversal = true      
        const bankData = this.bankData || {}
        const consumerData = this.getDataConsumer || []
        this.form_data.bank = Object.assign({}, this.form_data.bank, bankData)  
        if(Object.entries(this.form_data.bank).every(([key, value]) => value !== '')) {          
          this.setSelectBank() 
          this.maskedPix()  
          return 
        } 
        if (consumerData.length > 0 && consumerData[0].BAN_CODIGO && consumerData[0].CLI_AGENCIA
        && consumerData[0].CLI_CONTA && consumerData[0].CLI_CHAVEPIX) {
          const item = consumerData[0]          
          this.form_data.bank.ban_codigo = item.BAN_CODIGO
          this.form_data.bank.cli_agencia = item.CLI_AGENCIA
          this.form_data.bank.cli_conta = item.CLI_CONTA
          this.form_data.bank.cli_chavepix = item.CLI_CHAVEPIX
        }           
        this.setSelectBank() 
        this.maskedPix()  
      } else {
        this.isPaymentReversal = false
      }
    },
    editForm() {
      this.editMode = true      
      this.disabled_fields = []
      this.$emit('editModeAction', this.editMode)
    },
    confirmEdit() {
      if(this.validateDataRequired() && this.validateBankAndPhone()) {
        this.editMode = false
        this.$emit('editModeAction', this.editMode)
      } else {
        this.$ShowAlert({
          title: 'Atenção',
          message: this.$t('erro.fieldsRequired'),
          type: 'error',
          hideDelay: 2500
        })  
      }     
    },
    handlerDisableField: function () {
      const self = this
      const form_data = this.form_data      
      Object.entries(form_data.buyer).forEach(([key, value]) => {
        if (value !== '') self.disabled_fields.push(key)
      })
      Object.entries(form_data.address).forEach(([key, value]) => {
        if (value !== '') self.disabled_fields.push(key)
      })
      if(this.isPaymentReversal) {
        Object.entries(form_data.bank).forEach(([key, value]) => {
          if (value !== '') self.disabled_fields.push(key)
        })        
      }
    },
    isValidDataAddress() {
      if (!this.validateCep() || !this.validateCpf() || !this.validateEmail()) {
        return false
      }
      else {
        return true
      }
    },
    isValidDataBank() {
      if(this.isPaymentReversal) {
        if (!this.validatePix() || !this.validateAgencia() || !this.validateConta()) {
          return false
        }
        else {
          return true
        }
      }    
      return true 
    },
    validateDataRequired() {
      if(this.isPaymentReversal) {
        propertiesRequired.push(...[
          'bank.ban_codigo',
          'bank.cli_agencia',
          'bank.cli_conta',
          'bank.cli_chavepix'
        ])
      }       
      return propertiesRequired.every((property) => {
        const value = property
          .split('.')
          .reduce((obj, prop) => obj && obj[prop], this.form_data)
        return value !== null && value !== undefined && value !== ''
      })
        
    },    
    validateCpf() { 
      if (this.form_data.buyer.cpf.replace(/[^\d]+/g, '').length != 11 || !this.form_data.buyer.cpf)  {
        this.form_data.buyer.cpf = ''
        this.invalidCpf = true
        return false
      }      
      const isValid = cpfValidator(this.form_data.buyer.cpf) 
      this.invalidCpf = !isValid      
      return isValid
        
    },
    validateEmail() {
      const isValid =  emailValidator(this.form_data.buyer.email)
       
      if(!isValid) {
        this.form_data.buyer.email = ''
        this.invalidEmail = true
        return false
      }      
      this.invalidEmail = !isValid
      return isValid
    },
    validateCep() {
      const isValid = this.form_data.address.cep.replace(/[^\d]/g, '').length == 8
      this.invalidCep = !isValid
      return isValid
    },
    setSelectBank() {       
      this.optionsBanks = autoSelectedItemInList(this.form_data.bank.ban_codigo, this.optionsBanks)
    }, 
    async loadDataCep() {
      const cep = this.form_data.address.cep.replace(/[^\d]/g, '')
      
      if(cep.length != 8) {
        this.invalidCep = true 
        this.form_data.address.cidade = ''
        this.form_data.address.uf = ''
        return
      }
      this.invalidCep = false
      this.disabledFieldWhileGetData = true
      this.disabledCityField = true 
      this.disabledUfField = true
      try {
        const data = await this.getDataCeps(cep) 
        this.disabledFieldWhileGetData = false      
        if(data.length > 0 ) {
          this.form_data.address.cidade = data[0].LOC_NOME
          this.form_data.address.uf = data[0].UF_CODIGO
        } else {
          this.form_data.address.cep = ''
          this.form_data.address.cidade = ''
          this.form_data.address.uf = ''
          this.$ShowAlert({
            variant: 'error',
            message: this.$t('dass.zipCodeNotFound'),
            hideDelay: 2000
          })
        }
      } catch (error) {
        this.disabledFieldWhileGetData = false    
        this.disabledCityField = false
        this.disabledUfField = false            
      }
    },
    hasProdutoNome() {
      return (
        (Array.isArray(this.dados_item) && this.dados_item.length && this.dados_item[0].hasOwnProperty('produtoNome')) ||
      this.dados_item.hasOwnProperty('produtoNome')
      )
    },
  },
  created: async function () { 
    await this.fillForm()    
    if (this.hasProdutoNome()) {      
      return
    }
    this.showToast({
      variant: 'warning',
      message: this.$t('erro.carregar'),
    })
    return this.cancel()
  }  
  
}
</script>
  
  <style lang="scss">
  .buyer-data {
    width: 100%;  
    height: auto;
    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;    
      gap: var(--space);      
       .dass-form-data-client {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: var(--space__0-75x);
        h3 {
          width: 100%; 
          white-space: nowrap;    
          margin-bottom: 0;           
        }      
        > div { 
          flex: 1;      
          > div {
            margin-top: var(--space__0-75x);
          }
          label {
            font-weight: bold !important;
          }
          div.dass-input-text--input-wrapper {
            margin-top: 0;
          }
        }
      }
      .dass-form-data-bank {           
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: var(--space__0-75x);
        h3 {
          width: 100%;    
          margin-bottom: 0;    
        }      
        > div {  
          flex: 1;      
          > div {    
            flex: 1;     
            margin-top: var(--space__0-75x);
          }
          label {
            font-weight: bold !important;
          }
          div.dass-input-text--input-wrapper {
            margin-top: 0;
          }
        }
      }
      .dass-form-data-delivery {           
        flex: 3;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: var(--space__0-75x);
        h3 {
          width: 100%;    
          margin-bottom: 0;    
        }      
        > div {  
          flex: 1;      
          > div {    
            flex: 1;     
            margin-top: var(--space__0-75x);
          }
          label {
            font-weight: bold !important;
          }
          div.dass-input-text--input-wrapper {
            margin-top: 0;
          }
        }
      }
      .is-payment-reversal {
        @media (max-width: 1200px) {
          flex: none;
          width: 100%;          
        }
      }
  
      .actions {        
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;      
      }
    }
    #dass-button-tertiary-close-umbro {
      color: var(--theme-color) !important;    
    }
    small {
      color: red;
      margin-left: var(--space__0-5x);
    }
  }  
  </style>
  