import Protocol from '@/services/protocol'
import Files from '@/services/files'
import * as types from './mutation-types'
import store from '../..'

export const loadSubjects = async ({ commit }, filters) => {
  const defaultFilters = {
    ass_id: '',
    cto_id: '',
    ass_ativo: 'S',
    ass_nome: '',
  }
  const requestBody = Object.assign({}, defaultFilters, filters)
  let subjects = await Protocol.loadSubjects(requestBody)
  subjects = subjects.productBalance

  commit(types.SET_SUBJECTS, { subjects })
  return subjects
}

export const loadReasons = async ({ commit }) => {
  let reasons = await Protocol.getReasons()  
  reasons = reasons.reasons
  
  commit(types.SET_REASONS, { reasons })
  return reasons
}

export const loadSubReasons = async ({ commit }, fields) => {  
  try {
    let subReasons = await Protocol.getSubReasons(fields)
    subReasons = subReasons.subReasons    
    if(!fields) {     
      commit(types.SET_ALL_SUB_REASONS, { subReasons })
      return subReasons
    } 
    commit(types.SET_SUB_REASONS, { subReasons })
    return subReasons    
  } catch (error) {
    
    throw error
    
  }
}

export const loadDataConsumer = async ({ commit }) => {
  const emailUser = store.getters['session/getUsuEmail'] || ''

  let consumers = await Protocol.getDataConsumer({email:emailUser})
  consumers = consumers.consumers || []

  commit(types.SET_DATA_CONSUMERS, { consumers })
  return consumers
}
export const loadBankData = async ({ commit }) => {
  let response = await Protocol.loadBankData()
  let banks = response.bankData || []
  if(banks.length > 0) {
    sortedBanksAndFilterOptions(banks)
  }
  commit(types.SET_BANK_DATA, { banks })
  return banks
}
const sortedBanksAndFilterOptions = (banks) => {
  let banksFormated = banks.sort((a, b) => {
    if (a.BAN_CODIGO < b.BAN_CODIGO) return -1
    if (a.BAN_CODIGO > b.BAN_CODIGO) return 1
    return 0
  })
  banksFormated = banksFormated.filter(item => 
    item.BAN_NOME.trim() !== 'AJUSTE EXERCICIOS ANTERIORES' && item.BAN_NOME.trim() !== '(Alterado para 33)'
    && item.BAN_NOME.trim() !== 'AJUSTE' && item.BAN_NOME.trim() !== 'OUTROS'
     &&  item.BAN_NOME.trim() !== 'Nao usar' && item.BAN_NOME.trim().toUpperCase() !== 'MUTUOS'
     && item.BAN_NOME.trim() !== 'DEVOLUCAO' && item.BAN_NOME.trim() !== 'TRANSPORTADORAS' && 
     !item.BAN_NOME.includes('LIQUIDACAO') && item.BAN_NOME.trim() !== 'FILA DO BRASIL'
  )
  return banksFormated
}
export const createDados = async ({ commit }, dados) => {
  const brand = store.getters['brand/getBrand'] || {}
  const marcaCodigo = brand.id || ''

  let defaultDados = {
    origem: 'ecom',
    marcaCodigo,
    nomeCliente: '',
    cpfCliente: '',
    emailCliente: '',
    telefoneCliente: '',
    cepCliente: '',
    enderecoCliente: '',
    numeroEndrCliente: '',
    bairroCliente: '',
    cidadeCliente: '',
    UFCliente: '',
    complementoEndrCliente: '',
    tipo_contato: ''
  }
  const dadosGerais = Object.assign({}, defaultDados, dados)
  
  commit(types.FILL_DADOS_GERAIS, { dadosGerais })
  return dadosGerais
}

export const createBankData = async ({ commit }, dados) => {

  let defaultData = {
    ban_codigo: '',
    cli_agencia: '',
    cli_conta: '',
    cli_chavepix: ''
  }

  const bankData = Object.assign({}, defaultData, dados)
  commit(types.FILL_BANK_DATA, { bankData })
  return bankData
}

export const createItem = async ({ commit }, item) => {
  const brand = store.getters['brand/getBrand'] || {}
  const empresaCodigo = brand.id || ''

  let defaultItem = {
    itemDevolucao: 1,
    agrupador: '',
    produtoCodigo: 0,
    produtoNome: '',
    produtoCor: '',
    produtoValor: '',
    motivoCodigo: 0,
    motivoNome: '',
    desejoCodigo: 0,
    desejoNome: '',
    gradeCodigo: 0,
    gradeTamanho: '',
    tamanhoTroca: '',
    empresaCodigo,
    filialCodigo: 1,
    sequenciaFat: 0,
    itemFatSequencia: 0,
    numeroNF: 0,
    quantidadeNF: 1,
    qtdDevolucao: 1,
    observacao: '',
    nomeLoja: '',
    cnpjLoja: '',
    telefoneLoja: '',
    dataCompraLoja: '',
    enderecoLoja: '',
    cidadeLoja: '',
  }

  let dadosItens = Object.assign({}, defaultItem, item)  
  commit(types.FILL_DADOS_ITENS, { dadosItens })
  return dadosItens
}

export const createItens = async ({ commit }, itens) => {
  commit(types.FILL_DADOS_ITENS, { dadosItens: itens })
  return itens
}

export const clearDataProtocol = ({commit}) => {
  commit(types.FILL_DADOS_ITENS, { dadosItens: [] })
  commit(types.FILL_DADOS_GERAIS, { dadosGerais: {} })
  commit(types.FILL_BANK_DATA, { bankData: {} })  
}

export const clearPicturesList = ({commit}) => {
  commit(types.SET_PICTURE_LIST, { picture_list: [] }) 
}

function formatPixToSend(pix) {
  if(!pix.includes('@')) {
    return pix.replace(/[().\-\s]/g, '')
  }
  return pix
}
export const generateProtocol = async ({ commit }, protocolData = {}) => {
  let requestBody = protocolData

  if (!protocolData.hasOwnProperty('dadosGerais')) {
    const dadosGerais = store.getters['create_protocol/getDadosGerais'] || {}
    requestBody.dadosGerais = dadosGerais
  }
  if (!protocolData.hasOwnProperty('bankData')) {
    const bankData = store.getters['create_protocol/getBankData'] || {
      ban_codigo: '',
      cli_agencia: '',
      cli_conta: '',
      cli_chavepix: ''
    }
    if(bankData.hasOwnProperty('cli_chavepix') && bankData.cli_chavepix) {
      bankData.cli_chavepix = formatPixToSend(bankData.cli_chavepix)
    }
    requestBody.dadosBancarios = bankData
  }
  if (!protocolData.hasOwnProperty('dadosItem')) {
    const dadosItem = store.getters['create_protocol/getDadosItens'] || []
    requestBody.dadosItens = [{ ...dadosItem }]
    if (Array.isArray(dadosItem)) {
      requestBody.dadosItens = dadosItem
    }
  }
  sessionStorage.setItem('protocolData', JSON.stringify(requestBody))
  const protocol = await Protocol.generateProtocol(requestBody)
  return protocol
}

export const setCurrentStep = async ({ commit }, step_num) => {
  commit(types.SET_CURRENT_STEP, { step_num })
  return step_num
}

export const setItensToAddBankData = async ({ commit }, itensToAddBankData) => {
  commit(types.SET_ITENS_TO_ADD_BANK_DATA, { itensToAddBankData })
  return itensToAddBankData 
}

export const setPictureList = async ({ commit }, picture_list) => {
  commit(types.SET_PICTURE_LIST, { picture_list })
  return picture_list
}

export const uploadFileList = async ({ commit }, prt_id) => {
  const picture_list = store.getters['create_protocol/getPictureList'] || []
  const promises = []
  picture_list.forEach((picture) => {
    let fields = {
      uploadFile: picture.original_file,
      prt_id,
    }
    promises.push(uploadFile(fields))
  })

  return await Promise.all(promises)
}

export const uploadFile = async (fields) => {
  const default_fields = {
    uploadFile: 'FILE',
    prt_id: '',
  }

  const request_body = Object.assign({}, default_fields, fields)
  const response = await Files.uploadFile(request_body)

  return response
}
