<template>
  <div :class="(expanded ? 'expanded' : '') + ' order'">
    <div class="thumbnail">
      <img :src="order.LINK_IMAGEM" :alt="order.PRO_NOME" class="dass-img-order"/>
      <span v-if="isItemSelected" :class="`dass-icon-returns-${brandName}`"></span>
    </div>
    <div class="detalhes">
      <div class="dados-compra">
        <div class="titulo">
          <b>{{ $t('etapas.produto.tituloCompra') }}:</b>
        </div>

        <div class="dados">
          <div class="apenas-expandido">
            <b>{{ order.PRO_NOME }}</b>
          </div>
          <div class="apenas-expandido cor-tamanho">
            <div class="flex">
              <div class="dass-product-size-color">
                <span>{{ $t('etapas.produto.cor') }}: </span>
                <b>{{ order.COR_NOME }}</b>
              </div>
              <div class="dass-product-size-color">
                <span>{{ $t('etapas.produto.tamanho') }}: </span>
                <b>{{ order.GRA_TAMANHO }}</b>
              </div>
            </div>
          </div>
          <div class="numero-pedido">
            <span>{{ $t('dass.numeroPedido') }}:</span>
            <b>{{ order.PED_CODIGO }}</b>
          </div>
          <div>
            <span>{{ $t('etapas.produto.tracking') }}:</span>
            <b>{{ order.VOL_RASTREIO }}</b>
          </div>
          <div class="data-valor-compra">
            <div>
              <span>{{ $t('etapas.produto.deliveryDate') }}:</span>
              <b>{{ $filters.toDateBRL(order.DATA_ENTREGA, 'DD/MM/YY') }}</b>
            </div>
            <div>
              <span>{{ $t('etapas.produto.valueProduct') }}:</span>
              <b>{{ $filters.toMoney(order.ITE_VALBRUTO || 0) }}</b>
            </div>            
          </div>
        </div>
      </div>
    </div>

    <div class="trocar-devolver">
      <form class="trocar-devolver" action="" @submit.prevent="formSubmit">
        <div>
          <b>{{ $t('home.trocaDevolucao') }}:</b>
        </div>
        <div class="campos">
          <div class="campo selects" :class="!isPaymentReversal && !isExchangeSize ? 'space-between-itens' : 'row-gap-itens'">
            <dass-select
              name="select-motivo"
              :label="$t('etapas.produto.motivo') + ':'"
              :required="true"
              :options="motivos"              
              @change="
                (val) => {
                  if (typeof val == 'object') return
                  form_data.motivo = val
                  handleReasons(val)
                }
              "
            >
            </dass-select>
            <dass-select
              name="select-motivo"
              :id="'dass-sub-motivo-select' + this.order.UNIQUE_KEY"
              :label="$t('etapas.produto.subMotivo') + ':'"              
              :required="true"
              :options="filteredSubReasons"
               :disabled="disabledFields"
              @change="
                (val) => {
                  if (typeof val == 'object') return
                  form_data.smotivoCodigo = val
                }
              "
            >
            </dass-select>
            <dass-select
              name="select-desejo"
              :id="'dass-select-desejo' + this.order.UNIQUE_KEY"
              :label="$t('etapas.produto.desejo') + ':'"
              :required="true"
              :options="filteredDesires"              
              :value="form_data.desejo"
               :disabled="disabledFields"
              @change="
                (val) => {
                  if (typeof val == 'object') return
                  form_data.desejo = val
                  handleDesire(val)
                }
              "
            >
            </dass-select>
            <div v-if="isExchangeSize && isExchangeColor || isPaymentReversal">
              <dass-textarea
                type="text"
                :required="true"
                :label="$t('etapas.produto.algoAcrescentar') + ':'"
                @change="
                  (val) => {
                    if (typeof val == 'object') return
                    form_data.detalhes = val
                  }
                "                
              >              
              </dass-textarea>            
            </div>
          </div>
          <div class="campo conjunto-tamanho">
            <div v-if="!isExchangeSize && !isPaymentReversal">
              <dass-textarea
                type="text"
                :required="true"
                :label="$t('etapas.produto.observacoes') + ':'"
                :disabled="disabledFields"
                @change="
                  (val) => {
                    if (typeof val == 'object') return
                    form_data.detalhes = val
                  }
                "                
              >              
              </dass-textarea>            
            </div>
            <div v-if="isExchangeSize && !isPaymentReversal && listUniqueSize.length > 0" :class="!this.isSafari ? 'input-troca' : ''">
              <CardSelectedItems
                v-if="isExchangeSize"
                :required="true"
                :value="form_data.tamanho"
                :listItems="listUniqueSize"
                :labelNameCard="$t('etapas.produto.selecioneNumeracao') + ':'"
                @selectItem="seletedItemNumbers"
              />
            </div>
            <div v-if="isExchangeSize && !isPaymentReversal && listUniqueSize.length == 0" :class="!this.isSafari ? 'input-troca' : ''">
              <dass-input-text
                :required="true"
                type="text"
                v-model="form_data.tamanho"
                v-mask="'XXX'"
                :label="$t('etapas.produto.tamanho') + ':'"
              />
            </div>
            <div v-if="isExchangeColor && !isPaymentReversal" :class="!this.isSafari ? 'input-troca' : ''">
              <CardSelectedItems
                v-if="isExchangeColor"
                :required="true"
                :isChangeColor="true"
                :value="form_data.corTrocaDesejada"
                :listItems="isExchangeSize && isExchangeColor ? listFilteredWithColorsSelected : listUniqueColors"
                :labelNameCard="$t('etapas.produto.selecioneCor') + ':'"
                @selectItem="seletedItemColors"
              />
            </div>
            <div v-if="isExchangeSize && !isExchangeColor && !isPaymentReversal">
              <dass-textarea
                type="text"
                :required="true"
                :label="$t('etapas.produto.algoAcrescentar') + ':'"
                @change="
                  (val) => {
                    if (typeof val == 'object') return
                    form_data.detalhes = val
                  }
                "                
              >              
              </dass-textarea>   
            </div>
            <div v-if="isPaymentReversal" class="dass-bank-data-container">
              <div class="dass-input-bank-data">
                <div class="dass-input-with-errors">
                  <dass-input-text
                    :required="true"
                    type="tel"
                    id="dass-input-agencia"
                    :disabled="this.disabledFields"
                    placeholder="0000-7"              
                    :label="$t('etapas.comprador.agencia') + ':'"                  
                    v-model="form_data.cli_agencia"                    
                    @blur="validateAgencia()"
                  />
                  <small v-if="invalidAgenciaField" v-html="this.$t('dass.invalidAgency') + '<br>' + 'Ex. 0000-7 (4 à 5 dígitos)'"/>  
                </div>
                <div class="dass-input-with-errors">
                  <dass-input-text
                    :required="true"
                    type="tel"
                    id="dass-input-conta"
                    :disabled="this.disabledFields"
                    :label="$t('etapas.comprador.conta') + ':'"
                    placeholder="0000-7"
                    v-model="this.form_data.cli_conta"
                    @blur="validateConta()"
                  />  
                  <small v-if="invalidContaField" v-html="this.$t('dass.invalidAccount') + '<br>' + 'Ex. 0000-7 (5 à 12 dígitos)'"/> 
                </div>
              </div>
              <div class="dass-input-bank-data">
                <dass-select
                  name="select-bank"
                  :label="$t('etapas.comprador.codigoBanco') + ':'"
                  :required="true"
                  :options="optionsBanks"                
                  id="dass-select-bank"
                  :disabled="this.disabledFields"
                  @change="
                    (val) => {
                      if (typeof val == 'object') return
                      form_data.ban_codigo = val                                       
                    }
                  "
                >
                </dass-select>  
              </div>
              <div class="dass-input-bank-data">
                <div class="dass-input-with-errors">
                  <dass-input-text
                    :required="true"
                    id="dass-input-pix"  
                    :disabled="this.disabledFields"            
                    type="text"                        
                    :label="$t('etapas.comprador.chavePix') + ':'"
                    :modelValue="this.pixKeyFormated"
                    v-model="this.form_data.cli_chavepix"
                    placeholder="CPF,CNPJ,Email ou Telefone"
                    @update:modelValue="maskedPix"
                    @blur="validatePix"                                    
                  />                                  
                  <small v-if="invalidPixField" v-html="this.$t('dass.invalidPix') + '<br>' + 'Formatos aceitos: CPF, CNPJ, email ou telefone.'"/>   
                </div>
              </div>
            </div>
            <div class="dass-actions-buttons-order">
              <dass-link
                :id="`dass-link-order-${this.brandName}`"                
                @click="
                  () => {
                    expanded = !expanded
                  }
                "
              >
                {{ $t('home.minimizar') }}
              </dass-link>

              <dass-button :class="this.isSafari ? `dass-button-primary-${this.brandName}` : ''" variant="primary"  :disabled="validateFieldsDataBankAndReasonNotPermitted() || validateIfExchange" type="submit">
                {{ label_btn }}
              </dass-button>
          </div>            
          </div>
        </div>
        <div class="dass-auxiliary-message-container">
          <AuxiliaryMessage 
            v-if="auxiliaryMessage"
            :message="auxiliaryMessage"
          />
        </div>  
      </form>
    </div>

    <div class="acoes">
      <dass-button
        class="button-acesso-rapido"
        variant="secondary"
        :disabled="isItemSelected"
        type="button"       
        @click="
          () => {
            expanded = !expanded
          }
        "
      >
        {{ $t('dass.visualizarItem') }}
      </dass-button>      
    </div>
   
  </div>
  <ModalExchange
    :showModal="showProtocolWithoutDeliveryDate"
    :messageSLA="true"
    :message="messageProtocolWithoutDeliveryDate"
    @closeModal="showProtocolWithoutDeliveryDate = false"
  />
  <ModalExchange
    :showModal="showModalOrderMarketplace"
    :messageSLA="true"
    :message="messageOrderMarketplace"
    @closeModal="showModalOrderMarketplace = false"
  />
  <ModalExchange
    :showModal="showModalRestrictionSLA"
    :messageSLA="true"
    :message="messageReasonNotPermittedCreateProtocol"
    @closeModal="showModalRestrictionSLA = false"
  />
  <ModalExchange
    :showModal="showModalNotPermittedSecondExchange"
    @closeModal="showModalNotPermittedSecondExchange = false"
  /> 
  <ModalShoppingVoucher
    :showModal="showModalVoucher" 
    :percentageBonus="bonusPercent"
    :shoppingVoucherValue="valueShoppingVoucher" 
    @closeModal="closeModal"
    @acceptShoppingVoucher="acceptShoppingVoucher"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { MOTIVOS, DESEJOS } from '../../../helpers/constants/motivos_desejos'
import { ctoId } from '../../../helpers/constants/ctoId'
import { useIsSafari } from '@/composables/useIsSafari'
import { cpfValidator } from '@/helpers/functions/cpfValidator'
import { pixValidator } from '@/helpers/functions/pixValidator'
import { agenciaValidator } from '@/helpers/functions/agenciaValidator'
import { contaValidator } from '@/helpers/functions/contaValidator'
import { typesPayment } from '@/helpers/constants/typesPayment'
import { formatOptionsBankToSelect } from '@/helpers/functions/formatOptionsBankToSelect'
import { maskPix } from '@/helpers/functions/maskPix'
import { autoSelectedItemInList } from '@/helpers/functions/autoSelectedItemInList'
import  ModalExchange from '../../molecules/ModalExchange.vue'
import CardSelectedItems from '../../molecules/CardSelectedItems.vue'
import ModalShoppingVoucher from '../../molecules/ModalShoppingVoucher.vue'
import AuxiliaryMessage from '../../molecules/AuxiliaryMessage.vue'
import { useSelectedOrderFromLocalStorage } from '@/composables/useSelectedOrderFromLocalStorage'
import moment from 'moment'

export default {
  name: 'Order',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    CardSelectedItems,
    ModalShoppingVoucher,
    AuxiliaryMessage,
    ModalExchange
  },
  setup() {
    const { isSafari, brandName } = useIsSafari()
    const { selectOrder, clearSelectedOrders, getSelectedOrders } = useSelectedOrderFromLocalStorage()
        
    return {
      isSafari,
      brandName,      
      selectOrder,      
      clearSelectedOrders,
      getSelectedOrders
    }
  },
  data() {
    return {      
      reasonsSelected: '',
      loadingListAvailable: false,
      isExchangeSize: false,
      isExchangeColor: false,
      auxiliaryMessage: '',
      bonusPercent: '',
      valueShoppingVoucher: '',      
      listProducts: [],
      listUniqueSize: [],
      listUniqueColors: [],
      listFilteredWithColorsSelected: [],
      existProtocolExchange: [],
      showModalNotPermittedSecondExchange: false,
      messageReasonNotPermittedCreateProtocol: '',
      messageOrderMarketplace: '',
      reasonNotPermittedCreateProtocol: false,
      notPermittedSecondExchange: false,
      isPermittedFirstExchange: true,
      isPermittedSecondOrMoreExchange: true,
      showModalOrderMarketplace: false,
      isOrderIsMarketplace: false,
      showModalVoucher: false,
      expanded: false,
      errorCount: 0,
      disabledFields: false,
      errorCountAllSubReasonsRequest: 0,
      isPaymentReversal: false,
      pixKeyFormated: '',
      invalidAgenciaField: false,
      invalidContaField: false,
      invalidPixField: false,
      acceptBonusShoppingVoucher: false,
      showModalRestrictionSLA: false,
      showProtocolWithoutDeliveryDate: false,
      messageProtocolWithoutDeliveryDate: '',
      form_data: {
        motivo: '',
        desejo: '',
        detalhes: '',
        tamanho: '',
        smotivoCodigo: '',
        corTrocaDesejada: '',
        cli_agencia: '',
        cli_conta: '',
        ban_codigo: '',
        cli_chavepix: '',
      },
      linkImage: '',
      optionsBanks: [],
      banks: [],
      MOTIVOS: {
        A_PECA_QUE_RECEBI_NAO_FOI_A_QUE_COMPREI: 19,
        O_PRODUTO_CHEGOU_COM_DEFEITO: 20,
      },
      filteredSubReasons: [{
        text: this.$t('etapas.produto.selecioneSubMotivo'),
        value: '',
        disabled: true,
        selected: true,
      }],
      filteredDesires: [{
        text: this.$t('etapas.produto.selecioneDesejo'),
        value: '',
        disabled: true,
        selected: true,
      }],
    }
  },
  computed: {
    ...mapGetters('create_protocol', {
      subjects: 'getSubjects',
      desires: 'getReasons',
      allSubReasons: 'getAllSubReasons',
      subReasons: 'getSubReasons',
      getDataConsumer: 'getDataConsumer',
      bankData: 'getBankData',
      getBanks: 'getBanks',
    }),    
    ...mapGetters('brand', { brand: 'getBrand' }), 
    ...mapGetters('protocols', ['getProtocols']),   
    validateIfExchange() {
      return (this.isExchangeSize || this.isExchangeColor) && !this.form_data.tamanho ? true : false
    },
    motivos: function () {
      let reasonsAvailable = []
      const ctoIdOrigin = ctoId[this.order.ORIGEM]  

      this.subjects.forEach((reason) => {
        if(reason.CTO_ID == ctoIdOrigin && reason.ASS_NOME && reason.ASS_ID) {
          reasonsAvailable.push({
            text: reason.ASS_NOME,
            value: reason.ASS_ID
          }) 
        }       
      })  
      
      if(!reasonsAvailable.some(item => item.value == ''))
        reasonsAvailable.unshift({
          text: this.$t('etapas.produto.selecioneMotivo'),
          value: '',
          disabled: true,
          selected: true,
        })
        
      return reasonsAvailable
    },
    reasonSelected: function () {
      if (!this.form_data.motivo) return
      return this.subjects.find((item) => {
        return item.ASS_ID == this.form_data.motivo 
      })
    },
    desireSelected: function () {
      if(!this.form_data.desejo) return
      return this.desires.find((item) => {
        return item.MOT_ID == this.form_data.desejo 
      })
    },    
    action_fotos: function () { 
      if (!this.reasonSelected) return false    
      const reason = this.reasonSelected
         
      return reason.ASS_EXIGE_IMG == 'S'      
    },
    isItemSelected(){      
      return (Array.isArray(this.getSelectedOrders) &&  this.getSelectedOrders.some(item => item.UNIQUE_KEY == this.order.UNIQUE_KEY)) || (typeof this.getSelectedOrders == 'object' && Object.values(this.getSelectedOrders).some(item => item.UNIQUE_KEY == this.order.UNIQUE_KEY))
    },
    label_btn: function () {
      if (this.action_fotos) return this.$t('home.confirmar')
      return this.$t('home.adicionar')
    },    
  },
  methods: {
    ...mapActions('create_protocol', ['createItem', 'loadSubReasons', 'createBankData','loadBankData']),
    ...mapActions('orders', ['toggleSidebar','loadBonus']),
    ...mapActions('protocols', ['loadProductBalanceB2C']),

    next: function () {
      this.$router.push({
        path: '/produtos-adquiridos-online/confirmacao',
      })
    },    
    acceptShoppingVoucher() {  
      this.acceptBonusShoppingVoucher = true       
      this.showModalVoucher = false    
    },
    closeModal () {  
      this.acceptBonusShoppingVoucher = false        
      this.showModalVoucher = false   
      this.valueShoppingVoucher = ''
      this.verifyIfPaymentIsBankSlipOrPix()    
    },    
    verifyIfPaymentIsBankSlipOrPix() {      
      this.isPaymentReversal =  this.order.PED_TIPOPGTO == typesPayment.BOLETO
      if(this.isPaymentReversal) {
        this.loadBank()
        this.getBankDataInConsumer()
      }
    },
    getBankDataInConsumer() {
      const consumerData = this.getDataConsumer      
      if(consumerData.length > 0 && consumerData[0].BAN_CODIGO && consumerData[0].CLI_AGENCIA
       && consumerData[0].CLI_CONTA && consumerData[0].CLI_CHAVEPIX) {
        const item = consumerData[0]
        this.form_data.ban_codigo = item.BAN_CODIGO
        this.form_data.cli_agencia = item.CLI_AGENCIA
        this.form_data.cli_conta = item.CLI_CONTA
        this.form_data.cli_chavepix = item.CLI_CHAVEPIX
        this.setSelectBank()
        this.maskedPix()  
      } else if(Object.entries(this.bankData).length > 0 && Object.values(this.bankData).every((value) => value !== '' && value !== null)) {     
        this.form_data = Object.assign(this.form_data, this.bankData)        
        this.setSelectBank()
        this.maskedPix()         
      } else {
        return
      }

      if (Object.values(this.form_data).every((value) => value !== '' && value !== null)) {
        this.disabledFields = true
        this.maskedPix() 
        this.setSelectBank()  
      } 
    },
    validateFieldsDataBankAndReasonNotPermitted() {
      if(!this.isPaymentReversal && !this.reasonNotPermittedCreateProtocol && !this.isOrderIsMarketplace) {
        this.disabledFields = false
        return false
      } else if (this.isPaymentReversal && (this.invalidAgenciaField || this.invalidContaField || this.invalidPixField)) {
        return true
      } else if (this.reasonNotPermittedCreateProtocol || this.isOrderIsMarketplace) {
        this.disabledFields = true
        return true
      }
      this.disabledFields = false
      return false
    },    
    setSelectBank() { 
      this.optionsBanks = autoSelectedItemInList(this.form_data.ban_codigo, this.optionsBanks)
    },    
    validateIfDesireIsPaymentReversal() {
      const desire = this.desireSelected.MOT_NOME.toLowerCase()
      return desire.includes('estorno')
    },    
    formSubmit: function () {
      const order = this.order
      const notSelectedItemExchange = this.validateIfExchange
      if(notSelectedItemExchange) {
        return this.$ShowAlert({
          variant: 'warning',
          message: this.$t('dass.selectedDesiredSize')
        })
      }
      const isValidSubReason = this.validateSubReasons()
      if(!isValidSubReason) return      
      let item = {
        itemDevolucao: order.QUANTIDADENF,
        agrupador: '',
        pedTipoPgto: order.PED_TIPOPGTO,
        produtoCodigo: order.PRO_CODIGO,
        produtoNome: order.PRO_NOME,
        produtoCor: this.form_data.corTrocaDesejada || order.COR_NOME,
        produtoValor: parseFloat(order.ITE_VALBRUTO || 0),
        motivoCodigo: parseInt(this.reasonSelected.ASS_ID || 0),
        motivoNome: this.reasonSelected.ASS_NOME || '',
        smotivoCodigo: this.form_data.smotivoCodigo || '',
        desejoCodigo: this.desireSelected.MOT_ID,
        desejoNome: this.desireSelected.MOT_NOME,
        gradeCodigo: order.GRA_CODIGO,
        gradeTamanho: order.GRA_TAMANHO,
        tamanhoTroca: this.form_data.tamanho || order.GRA_TAMANHO,
        empresaCodigo: order.PED_EMPRESA || '',
        filialCodigo: order.PED_FILIAL,
        sequenciaFat: order.FAT_SEQUENCIA,
        itemFatSequencia: order.ITE_SEQUENCIA,
        numeroNF: order.NUMERONF,
        quantidadeNF: order.QUANTIDADENF,
        qtdDevolucao: order.QUANTIDADENF,
        observacao: this.form_data.detalhes || '',
        ecommerce: true,
        tipo_contato: order.ORIGEM == 'eComm' ? ctoId.eComm : order.ORIGEM == 'Omni' ? ctoId.Omni : ctoId.Reclamacao,
        thumbnail: this.linkImage || order.LINK_IMAGEM,
        dataCompra: this.$filters.toDateBRL(order.DATA_ENTREGA, 'DD/M/YY') || '',
        compraValor: order.PED_VALTOTAL || '',
        motivo: this.reasonSelected || '',
        desejo: this.desireSelected || '',
        UNIQUE_KEY: order.UNIQUE_KEY || '',
        isRequiredImageToProduct: this.action_fotos,
        isPaymentReversal: this.isPaymentReversal,        
        prt_valor_bonus: this.acceptBonusShoppingVoucher ? this.valueShoppingVoucher : 0,
        prt_sit_bonus: this.acceptBonusShoppingVoucher ? 'S' : 'N',
        motivoGatilho: this.desireSelected.MOT_GATILHO || '',
      }

      this.selectOrder(item)
      if(this.isPaymentReversal) {
        const dataBank = {       
          ban_codigo: this.form_data.ban_codigo,
          cli_agencia: this.form_data.cli_agencia,
          cli_conta: this.form_data.cli_conta,
          cli_chavepix: this.form_data.cli_chavepix
        }
        this.createBankData(dataBank)
      }
      if (this.action_fotos) {
        this.clearSelectedOrders()
        this.createItem(item)
        this.selectOrder(item)
        return this.next()
      }
      this.toggleSidebar(true)
    },
    clearSubMotivoAndDesejoAndSettings() {      
      this.form_data.smotivoCodigo = ''
      this.form_data.desejo = ''
      const eleSubReason = document.getElementById('dass-sub-motivo-select' + this.order.UNIQUE_KEY)
      const eleDesejo = document.getElementById('dass-select-desejo' + this.order.UNIQUE_KEY)
      eleDesejo.value = ''
      eleSubReason.value = ''
      this.clearSettings()      
    },
    async verifyIfExistExchangeForOrder(assId) {  
      this.isPermittedFirstExchange = this.subjects.some((item) => {
        return item.ASS_ID == assId && item.ASS_PRI_TROCA == 'S'
      })
      this.isPermittedSecondOrMoreExchange = this.subjects.some((item) => {
        return item.ASS_ID == assId && item.ASS_SEG_TROCA == 'S'
      })      
      if (this.isPermittedFirstExchange && !this.isPermittedSecondOrMoreExchange && this.order.TIPO_REFATU == 'S') {
        this.showModalNotPermittedSecondExchange = true
        this.notPermittedSecondExchange = true        
      } else if (this.isPermittedFirstExchange && this.isPermittedSecondOrMoreExchange || this.isPermittedFirstExchange && !this.isPermittedSecondOrMoreExchange && this.order.TIPO_REFATU == 'N') {
        this.notPermittedSecondExchange = false
      } 
    },    
    verifyIfReasonIsPermittedChangeSizeAndColor(motId) {         
      this.isExchangeSize = this.desires.some((item) => {
        return item.MOT_ID == motId && item.MOT_TAMANHO == 'S'
      })  
      
      if(!this.isExchangeSize) {
        this.isExchangeColor = this.desires.some((item) => {
          return item.MOT_ID == motId && item.MOT_COR == 'S'
        })        
      }
    }, 
    clearSettings() {
      this.isExchangeSize = false
      this.isExchangeColor = false 
      this.form_data.tamanho = ''
      this.form_data.corTrocaDesejada = ''
      this.linkImage = ''
      this.auxiliaryMessage = ''
      this.valueShoppingVoucher = ''
      this.isExchangeColorAndSize = false  
      this.isPaymentReversal = false

    },
    handleDesire(motId) {  
      this.clearSettings()        
      this.verifyIfReasonIsPermittedChangeSizeAndColor(motId)
      this.verifyIfDesireIsPaymentReversal(motId)
    },
    async verifyIfDesireIsPaymentReversal(motId) {  
      this.auxiliaryMessage = ''
      const desire = this.desires.find((item) => {
        return item.MOT_ID == motId
      })
      const nameDesire = desire.MOT_NOME.toLowerCase()      
      this.auxiliaryMessage = desire && desire.MOT_MSG_AUXILIAR && desire.MOT_MSG_AUXILIAR.trim() ?  desire.MOT_MSG_AUXILIAR : false
      
      const isPaymentReversalAndAcceptedShoppingVoucher = desire && desire.MOT_ESTORNO == 'S' && desire.MOT_VALE_COMPRAS == 'S' && nameDesire.includes('estorno')
      
      if (isPaymentReversalAndAcceptedShoppingVoucher) {
        const idBonus = desire.BON_CODIGO
        const bonus = await this.loadBonus({ bonCodigo: idBonus })        
        const valueItem = this.order.ITE_VALBRUTO        
        this.bonusPercent = bonus.length > 0 ? bonus[0].BON_PERCENTUAL : 0
        this.sumValueOfProductWithBonus(this.bonusPercent, valueItem)
        this.showModalVoucher = true
      }
    },
    sumValueOfProductWithBonus(bonusPercent, valueItem) {
      this.valueShoppingVoucher = valueItem * (1 + (bonusPercent / 100))
    },
    filterSubReasonsOfReason(val) { 
      this.filteredSubReasons = []
      const ctoIdOrigin = ctoId[this.order.ORIGEM]       
      
      this.allSubReasons.forEach((subReason) => {        
        if(subReason.CTO_ID == ctoIdOrigin && subReason.SMOT_NOME && subReason.SMOT_ID && subReason.ASS_ID == val) {
          this.filteredSubReasons.push({
            text: subReason.SMOT_NOME,
            value: subReason.SMOT_ID
          })
        }  
      })
      this.filteredSubReasons.unshift({
        text: this.$t('etapas.produto.selecioneSubMotivo'),
        value: '',
        disabled: true,
        selected: true,        
      })         
    },
    filterDesires(assId) { 
      const reasonObject = this.subjects.find((item) => {
        return item.ASS_ID == assId
      })  
      this.filteredDesires = [] 
      this.desires.forEach((desejo) => {
        const conditions = [
          desejo.MOT_ESTORNO == reasonObject.ASS_PER_DEVOLUCAO,
          desejo.MOT_COR == reasonObject.ASS_PRI_TROCA && !this.notPermittedSecondExchange,
          desejo.MOT_TAMANHO == reasonObject.ASS_PRI_TROCA && !this.notPermittedSecondExchange,
        ]  
        if (desejo.MOT_NOME && desejo.MOT_ID && desejo.MOT_ATIVO == 'S' && desejo.ASS_ID == reasonObject.ASS_ID && conditions.some((condition) => condition)) {
          this.filteredDesires.push({
            text: desejo.MOT_NOME,
            value: desejo.MOT_ID
          })
        }
      })
             
      this.filteredDesires.unshift({
        text: this.$t('etapas.produto.selecioneDesejo'),
        value: '',
        disabled: true,
        selected: true,       
      })      
    },
    verifyIfReasonIsPermittedChangeColorsOrSize(assId) { 
      const isPermitted = this.desires.some((item) => {
        
        return item.ASS_ID == assId && (item.MOT_TAMANHO == 'S' || item.MOT_COR == 'S')
      })
      return isPermitted      
    },
    verifyIfOrderOfMarketplace(reason) {
      if(this.order.PEDIDO_MKTP == 'S') {
        this.isOrderIsMarketplace = true
        this.showModalOrderMarketplace = true
        this.messageOrderMarketplace = reason.ASS_MSG_VEN_MKTP
        return true
      } else {
        this.isOrderIsMarketplace = false
        this.showModalOrderMarketplace = false
        this.messageOrderMarketplace = ''
        return false
      }
    },
    verifyIfExistRestrinctionSLAForOrder(assId) {
      if(!this.order.DATA_ENTREGA)  {
        this.reasonNotPermittedCreateProtocol = true
        this.showProtocolWithoutDeliveryDate = true
        this.messageProtocolWithoutDeliveryDate = this.$t('dass.deliveryDateRequired')        
        return
      } else {
        this.reasonNotPermittedCreateProtocol = false
        this.showProtocolWithoutDeliveryDate = false
        this.messageProtocolWithoutDeliveryDate = ''
      }
      const reason = this.subjects.find((item) => item.ASS_ID == assId) 
      const isOrderMarketplace = this.verifyIfOrderOfMarketplace(reason)
      if(isOrderMarketplace) return
      const today = moment()
      const dateOrderFormatada = moment(this.order.DATA_ENTREGA, 'DD/MM/YY')
      const daysDifference = today.diff(dateOrderFormatada, 'days')
            
      if(reason.ASS_RESTRICAO_SLA == 'S' && daysDifference >= reason.ASS_SLA_TROCA) {
        this.showModalRestrictionSLA = true
        this.reasonNotPermittedCreateProtocol = true        
        this.messageReasonNotPermittedCreateProtocol = reason.ASS_MSG_FORA_PRAZO
      } else  {
        this.showModalRestrictionSLA = false
        this.reasonNotPermittedCreateProtocol = false
        this.messageReasonNotPermittedCreateProtocol = ''
      }  
    },
    async handleReasons(assId) {      
      if(assId) {  
        this.clearSubMotivoAndDesejoAndSettings()
        this.verifyIfExistRestrinctionSLAForOrder(assId)
        this.verifyIfExistExchangeForOrder(assId)
        this.filterSubReasonsOfReason(assId)  
        this.filterDesires(assId)

        try {          
          if(this.verifyIfReasonIsPermittedChangeColorsOrSize(assId)) {  
            this.disabledFields = true         
            this.listProducts = await this.loadProductBalanceB2C({
              pro_modelo: this.order.PRO_MODELO,
            })         
            this.disabledFields = false    
            this.listUniqueSize = this.listProducts.reduce((acc, current) => {
              if (!acc.find((item) => item.GRA_TAMANHO == current.GRA_TAMANHO)) {
                acc.push(current)
              }
              return acc
            }, [])    
            this.listUniqueColors = this.listProducts
              .sort((a, b) => b.EST_TPEST - a.EST_TPEST)
              .reduce((acc, current) => {
                if (!acc.find((item) => item.COR_CODIGO === current.COR_CODIGO)) {
                  return [...acc, current]
                }
                return acc
              }, [])
            this.listUniqueColors = this.listUniqueColors.length == 0 ? this.listProducts : this.listUniqueColors 
            this.listUniqueSize = this.listUniqueSize.length == 0 ? this.listProducts : this.listUniqueSize             
            
          }
        } catch (error) {
          this.disabledFields = false      
          this.errorCount++
          this.$ShowAlert({
            variant: 'error',
            message: error.message,
            hideDelay: 3000
          })
          if(this.errorCount > 3) {
            this.errorCount = 0
            setTimeout(() => {
              location.reload()
            }, 3000)
          } else {
            setTimeout(() => {
              this.handleReasons(assId)
            }, 2000)
          }
        }
      }
    },
    validateSubReasons() {
      const requiredSubReasons = this.allSubReasons.some(item => {
        return item.ASS_ID == this.reasonSelected.ASS_ID
      })
      if(requiredSubReasons && !this.form_data.smotivoCodigo) {
        this.$ShowAlert({
          variant: 'warning',
          message: this.$t('etapas.produto.subMotivo')
        })
        return false
      }
      if(this.allSubReasons.length == 0) {
        location.reload()
      }
      return true
    },  
    verifyIfReasonIsPermittedChangeColors(itemSelected) {
      const motId = this.form_data.desejo 
      this.isExchangeColor = this.desires.some((item) => {
        return item.MOT_ID == motId && item.MOT_COR == 'S'
      })
      this.listFilteredWithColorsSelected = this.isExchangeColor
        ? this.listProducts
          .filter((item) => item.GRA_TAMANHO === itemSelected.GRA_TAMANHO)
          .sort((a, b) => b.EST_TPEST - a.EST_TPEST)
          .reduce((acc, current) => {
            if (!acc.find((item) => item.COR_CODIGO === current.COR_CODIGO)) {
              return [...acc, current]
            }
            return acc
          }, [])
        : []      
    },  
    seletedItemNumbers(item) { 
      this.form_data.tamanho = item.GRA_TAMANHO
      this.verifyIfReasonIsPermittedChangeColors(item)
    },
    seletedItemColors(item) {  
      this.linkImage = item.LINK_IMAGEM    
      this.form_data.corTrocaDesejada = item.COR_NOME
    },
    maskedPix() {
      if(this.form_data.cli_chavepix) {
        this.pixKeyFormated = maskPix(this.form_data.cli_chavepix)
      }
    },   
    validatePix() {   
      if(!pixValidator(this.form_data.cli_chavepix)) {   
        this.invalidPixField = true
      } else {
        this.invalidPixField = false      
      }
    },
    validateAgencia() {      
      if(!agenciaValidator(this.form_data.cli_agencia)) {
        this.invalidAgenciaField = true
      } else {
        this.invalidAgenciaField = false
      }
    },
    validateConta() {
      if(!contaValidator(this.form_data.cli_conta)) {
        this.invalidContaField = true
      } else {
        this.invalidContaField = false
        
      }
    },
    async loadBank() {      
      if(!this.getBanks || this.getBanks.length == 0) {        
        await this.loadBankData()
        if(this.getBanks.length > 0) {          
          this.optionsBanks = formatOptionsBankToSelect(this.getBanks, this.$t('dass.selectAnItem'))
        } 
      } else {        
        this.optionsBanks = formatOptionsBankToSelect(this.getBanks, this.$t('dass.selectAnItem'))
      }
    }
  }, 
   
}
</script>

<style lang="scss">
.order {
  border-bottom: 2px solid var(--theme-gray_light);
  &:last-of-type {
    border: none;
  }  
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  padding: var(--space) 0;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    align-items: flex-start;
    padding: var(--space__0-25x) 0;
    flex-wrap: wrap;
    padding-bottom: var(--space);
    &:last-of-type {
      padding-bottom: 0;
    }
  }
  .dass-auxiliary-message-container {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;    
  }
  .apenas-expandido {
    display: none;
  }
  .thumbnail {
    position: relative;
    transition: all 200ms;
    width: 100px;
    @media (max-width: 1024px) {
      width: 40%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .detalhes {
    flex: 1;
    padding: 0 var(--space);
    display: flex;
    min-width: 150px;
    @media (max-width: 1024px) {
      padding: 0;
      padding-left: var(--space);
      width: 60%;
    }
    span,
    b {
      line-height: 1rem;      
    }
    .dados-compra {
      width: 100%;
      .titulo {
        padding-bottom: var(--space__0-5x);
      }
      .dados {
        display: flex;
        @media (max-width: 1024px) {
          flex-wrap: wrap;
          line-height: 0.3rem;
          .flex > div {
            flex-wrap: wrap;
            display: flex;
            margin-bottom: var(--space__0-25x);
          }
        }
        .dass-product-size-color {
          @media (max-width: 700px) {
            row-gap: 0.3rem;            
          }
        }

        > div {
          padding-right: var(--space);
          margin-bottom: var(--space__0-5x);
          &:last-of-type {
            margin-bottom: 0;
          }
          @media (max-width: 1024px) {
            flex-wrap: wrap;            
            margin-bottom: var(--space__0-25x);
            span {
              width: 100%;
              display: block;
            }
          }
          span {
            margin-right: var(--space__0-5x);
            color: var(--theme-gray);
          }
        }
        .data-valor-compra {
          display: flex;
          flex-wrap: wrap;
          column-gap: var(--space);
          > div {
            margin-right: var(--space);
            @media (max-width: 1024px) {
              margin: 0;
              
              > span {
                margin-bottom: var(--space__0-25x);
              }
            }
          }
        }
        .flex {
          display: flex;
          flex-wrap: wrap;
          > div:first-of-type {
            margin-right: var(--space);
          }
        }
      }
    }
  }
  .trocar-devolver {
    height: 0;
    width: 0;
    overflow: hidden;
    transition: all 400ms;
    display: flex;    
    @media (max-width: 1024px) {
      width: initial;    
      flex-wrap: wrap;
      .campos {
        width: 100%;
        .campo > div {
          margin-bottom: var(--space);
        }
      }
      .dass-actions-buttons-order {   
        display: flex;     
        justify-content: space-between;
        gap: var(--space__0-5x);
        @media (max-width: 330px) {
          flex-direction: column;
          > a {
            display: flex;
            justify-content: center;

          }

        }
      }
    }
        
  }
  .acoes {
    transition: all 400ms;
    @media (max-width: 1024px) {
      width: 100%;
      margin-top: var(--space__0-5x);
      button {
        width: 100%;
      }
    }
    button {
      padding: var(--space__0-5x) var(--space__2x);
    }
  }
  &.expanded {    
    align-items: center;

    .apenas-expandido {
      display: block;
    }
    @media (max-width: 1024px) {
      .apenas-expandido {
        &.cor-tamanho {
          display: inline;
          span {
            width: initial !important;
          }
        }
      }
      .thumbnail {
        width: 35%;
      }
      .trocar-devolver {
        width: 100%;
        height: 100%;
      }
      .acoes {
        height: 0;
        overflow: hidden;
      }
    }
    @media (min-width: 1024px) {
      align-items: stretch;
      .thumbnail {
        width: 200px;
      }
      .detalhes {
        .dados-compra {
          width: 100%;
          .dados {
            flex-wrap: wrap;
            > div {
              width: 100%;
            }
            .numero-pedido {
               span {
                display: block;                           
              }
            }

            .data-valor-compra {
              display: flex;
              flex-wrap: wrap;
              > div {                
                margin-right: 0;
                span {
                  display: block;
                }
              }
            }
          }
        }
      }
      .trocar-devolver {
        height: initial;
        flex: 2;
        display: flex;
        flex-wrap: wrap;
        padding: 0 0.5rem;

        > div {
          width: 100%;
        }
        .campos {
          display: flex;          
          .campo {
            width: 50%;
            &:first-of-type {
              padding-right: 0.5rem;
            }
            &:last-of-type {
              padding-left: 0.5rem;
            }
            &.selects {
              display: flex;
              flex-direction: column; 
              row-gap: var(--space__0-5x);                      
            }
            &.space-between-itens {
              justify-content: space-between;
            }
            &.row-gap-itens {
              row-gap: var(--space__0-5x);
            }
          }
          .conjunto-tamanho .dass-textarea {
            padding-bottom: 1rem;
          }
          .conjunto-tamanho {
            display: flex;
            flex-direction: column;   
            justify-content: space-between;
            > div {
              display: flex;
              align-items: stretch;                             
              .dass-textarea,
              .dass-input-text {
                width: 100%;
                display: flex;
                height: 100%;
                .dass-input-text--input-wrapper {
                  width: 100%;
                }
                textarea,
                input {
                  width: 100%;
                  display: flex;
                  height: 100%;                  
                }
              }
            }
            > .input-troca {              
              margin-bottom: var(--space__0-5x)
            }
            .dass-actions-buttons-order {  
              width: 100%;      
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              gap: var(--space__0-5x);
              margin-top: var(--space__0-5x);
              margin-bottom: var(--space__0-5x);
              @media (max-width: 1532px) {
                > a {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                }
                > button {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .acoes {
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
  }
  @media(max-width: 1024px) {
    .numero-pedido {
      > span {
       margin-bottom: var(--space__0-25x);                           
     }
    }
  }
  #dass-link-order-newbalance {
    background-color: #E5788B !important;
    transition: background-color 0.3s ease !important;  
    padding: var(--space__0-5x); 
    border-radius: var(--theme-border-radius);
    &:hover {
      color: var(--theme-white) !important;
    }
  }
  #dass-link-order-fila {
    background-color: #758396 !important;
    padding: var(--space__0-5x); 
    &:hover {
      color: var(--theme-white) !important;
    } 
  }
  #dass-link-order-umbro {
    color: var(--theme-color) !important;
    padding: var(--space__0-5x);  
    background-color: #737373; 
    transition: background-color 0.3s ease !important;
    &:hover {
      background-color: #f0e7e79d !important;
    }
  }
  .dass-bank-data-container {    
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;    
    align-items: center;  
    gap: var(--space__0-5x);
    
    .dass-input-bank-data {
      width: 100%;
      display: flex;      
      align-items: flex-start;      
      column-gap: var(--space);
      > div {
        width: 100%;
      }
      .dass-input-with-errors {
        display: flex;
        flex-direction: column;
        width: 100%;
        > div {
          height: 57px !important; 
        }
        > small {
          color: red;          
        }
      }
      @media (max-width: 650px) {
        flex-direction: column;        
      }      
    }
  }
  .dass-icon-returns-fila {
      position: absolute;
      top: 2%;
      right: 2%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../../../assets/files/icons8-devolver-compra-fila.png");
      width: 1.5rem;
      height: 1.5rem;       
    }
    .dass-icon-returns-umbro {
      position: absolute;
      top: 2%;
      right: 2%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../../../assets/files/icons8-devolver-compra-fila.png");      
      width: 1.5rem;
      height: 1.5rem;      
    }
    .dass-icon-returns-newbalance {     
      position: absolute;
      top: 2%;
      right: 2%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../../../assets/files/icons8-devolver-compra-newbalance.png");
      width: 1.5rem;
      height: 1.5rem; 
    }
}
</style>
