<template>
    <div class="preview">
        <div
            class="picture-container"
            v-for="(picture, index) in getPictureList"
            v-bind:key="index"
        >
            <div class="actions">
                <a
                    class="action"
                    @click="removePicture(picture, index)"
                    :title="$t('etapas.fotos.remover')"
                >
                    <dass-icon token="icon-action-delete_outline" size="30" />
                </a>
            </div>
            <div class="picture" v-on:dblclick="zoomPicture(picture)">
                <img v-if="picture.file_type==='image'" :src="picture.object_url" alt="" />
                <object v-if="picture.file_type==='file'" :data="picture.object_url" :type="picture.type">
                    <img  alt="" :src="requireImage(`files/file.png`)" width="158" height="158"/>
                </object>
            </div>
        </div>
    </div>
    <ModalViewImg 
      :objectPicture="pictureToZoom"
      :showModal="showModal"
      :sizeModal="'large'"
      @closeModal="closeModalZoom"
    />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import requireImage from '@/helpers/functions/requireImage'
import ModalViewImg from '@/components/molecules/ModalViewImg.vue'

export default {
  name: 'PicturePreviewThumbnail',
  props: {
    picture_list: {
      type: Array,
      required: true
    },
  },
  components: {
    ModalViewImg
  },
  data() {
    return {
      showModal: false,
      pictureToZoom: null,
    }
  },
  computed: {
    ...mapGetters('create_protocol',['getPictureList'])
  },
  methods: {
    requireImage,
    ...mapActions('create_protocol', ['setPictureList']),
    removePicture(picture) {
      const pictureUpdate = this.getPictureList.filter(item => item.object_url !== picture.object_url)      
      this.setPictureList(pictureUpdate)     
    },
    zoomPicture(picture) {
      this.pictureToZoom = picture
      this.showModal = true
    },
    closeModalZoom() {
      this.showModal = false
      this.pictureToZoom = null
    }
  }

}

</script>

<style scoped>
.preview {
  display: flex;
  flex-wrap: wrap;  
  justify-content: center;
  gap: var(--space__0-75x);  
  .picture-container {
    background: var(--color-neutral__03);
    box-shadow: var(--shadow--sm) var(--color-neutral__30);
    display: flex;
    align-items: center;
    position: relative;    
    @media (max-width: 730px) {
      width: 46%;
      margin-bottom: var(--space);
    }
    .actions {
      position: absolute;
      right: var(--space__0-5x);
      top: var(--space__0-5x);
      display: flex;
      flex-direction: column;
      width: 35px;

      .action {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        width: 100%;
        cursor: pointer;
        background: var(--color-neutral__03);
        box-shadow: var(--shadow--sm) var(--color-neutral__30);
        border-radius: var(--border-radius-md);
        &:hover {
          background: var(--color-neutral__07);
        }
        &:not(:last-of-type) {
          margin-bottom: var(--space__0-5x);
        }
      }
    }
    .picture {
      cursor: pointer;
      height: auto;            
      max-width: 250px;
      max-height: 250px;
      display: flex;

      > img, object {
        width: 100%;
        height: auto;
        border-radius: var(--border-radius-md);
      }
    }
  }
}

</style>