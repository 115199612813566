<template>
  <div :class="`selected-orders ${open_class}`">
    <div class="backdrop" @click="toggleSidebar(false)"></div>
    <aside>
      <div class="dass-content-sidebar">
        <div class="close">
          <dass-button
          :class="this.isSafari ? `dass-button-tertiary-${this.brandName} button` : 'button'"
          :id="`dass-button-tertiary-close-${this.brandName}`"  
          variant="tertiary"
            type="button"
            @click="toggleSidebar(false)"
            >
            <dass-icon token="icon-navigation-close" size="30" />
          </dass-button>
        </div>
        <div class="titulo">
          <b class="count">{{ count_selected }}</b>
          <b class="texto">{{ $t('home.solicitacoes') }}</b>
        </div>
        <div class="sidebar-content">
          <div class="header">
            <h4>{{ $t('home.listagemProdutos') }}:</h4>
          </div>
          <div class="orders">
            <resumed-order
              v-for="order in getSelectedOrders"
              v-bind:key="order.UNIQUE_KEY"
              :order="order"
            />
          </div>
        </div>
      </div>
      <div :class="!this.isMobile ? 'dass-footer-sidebar' : 'dass-footer-sidebar-mobile'">
        <dass-button
          :class="this.isSafari ? `dass-button-tertiary-${this.brandName} button` : ' button'"
          :id="`dass-button-tertiary-close-${this.brandName}`"
          variant="tertiary"
          type="button"
          @click="toggleSidebar(false)"
        >
          {{ $t('dass.addMoreProduct') }}
        </dass-button> 
        <dass-button
          :class="this.isSafari ? `dass-button-primary-${this.brandName} button-acesso-rapido` : 'button-acesso-rapido'"         
          variant="primary"
          type="button"
          @click="next"
        >
          {{ $t('home.confirmar') }}
        </dass-button>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ResumedOrder from './ResumedOrder.vue'
import { useMobile } from '../../../../composables/useMobile'
import { useIsSafari } from '@/composables/useIsSafari'
import { useSelectedOrderFromLocalStorage } from '@/composables/useSelectedOrderFromLocalStorage'

export default {
  name: 'SideBar',
  components: { ResumedOrder },
  setup() {
    const { isMobile } = useMobile()      
    const { isSafari, brandName } = useIsSafari()   
    const { getSelectedOrders } = useSelectedOrderFromLocalStorage()
        
    return {
      isMobile,
      isSafari,
      brandName,     
      getSelectedOrders
    }    
  },
  computed: {
    ...mapGetters('orders', {      
      opened: 'getSidebarOpened',
    }),    
    ...mapGetters('brand', { brand: 'getBrand' }),
    count_selected: function () { 
      return Object.keys(this.getSelectedOrders).length 
    },    
    
  },  
  methods: {
    ...mapActions('orders', ['toggleSidebar']),
    ...mapActions('create_protocol', ['createItens']),

    next: function () {
      let items = Object.entries(this.getSelectedOrders).map(([key, el]) => {
        return el
      })
      this.createItens(items || [])
      this.toggleSidebar(false)
      this.$router.push({
        path: '/produtos-adquiridos-online/confirmacao',
      })
    },
    disableScroll: function () {
      document.body.classList.add('stop-scrolling')
    },
    enableSroll: function () {
      document.body.classList.remove('stop-scrolling')
    },
  },
  watch: {    
    opened: function (new_val, old_val) {
      if (new_val == old_val) return

      //opening
      if (!old_val && new_val) {
        this.open_class = 'opening'
        setTimeout(() => {
          this.open_class = 'opened'
        }, this.opening_duration)
        this.disableScroll()
        return new_val
      }

      // closing
      if (old_val && !new_val) {
        this.open_class = 'closing'
        setTimeout(() => {
          this.open_class = 'closed'
        }, this.opening_duration)
        this.enableSroll()
        return new_val
      }
    }, 
    deep: true   
  },
  data: () => ({
    open_class: 'closed',
    opening_duration: 200,    
  }),  
}
</script>

<style lang="scss" scoped>
.backdrop {
  width: 100%;
  @media (max-width: 576px) {
    width: 0;
  }
}
.selected-orders {
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;

  z-index: -1;
  opacity: 0;

  aside {
    overflow-y: scroll;
    max-width: 400px;
    width: 0;
    height: 100%;
    border: 1px solid var(--theme-gray_light);
    border-radius: var(--theme-border-radius) 0px 0px var(--theme-border-radius);
    background: var(--theme-white);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;

    @media (max-width: 576px) {
      padding-right: 0;
      max-width: 100%;
    }
    .close {
      position: absolute;
      right: 0;
      top: 0;
      text-align: right;
      background-color: var(--theme-white);
      padding: var(--space__0-5x);
      .dass-button-tertiary-newbalance {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #E5788B !important;
      }
    }
    .dass-button-primary-newbalance {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #E5788B !important;
      }
    }
    .dass-button-tertiary-umbro {
      color: var(--color-text--dark__90) !important;
      background-color: #737373 !important;
      transition: background-color 0.3s ease !important;
      &:hover {
       background-color: #f0e7e79d !important;
      }
    }
    }
    .titulo {
      padding: var(--space__1-5x);
      text-transform: uppercase;
      b {
        color: var(--theme-color) !important;
      }

      > .count {
        margin-right: var(--space__0-5x);
      }
    }
    .sidebar-content {
      padding: var(--space__1-5x);
      flex: 1;
      h4 {
        margin: 0;
      }
    }
    .dass-footer-sidebar {
      display: flex;
      flex-direction: column;
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      row-gap: var(--space__0-5x);      
      padding: var(--space__1-5x);
      button {
        width: 100%;
      }
    }
    .dass-footer-sidebar-mobile {
      display: flex;
      flex-direction: column;           
      width: 100%;      
      row-gap: var(--space__0-5x);      
      padding: var(--space__1-5x);      
      button {
        width: 100%;
      }
    }
  }

  & {
    transition: opacity 200ms linear 0s;
    aside {
      transition: width 200ms linear 0s;
    }
  }
  &.closing,
  &.closed {
    opacity: 0;
    aside {
      width: 0;
    }
  }
  &.closed {
    z-index: -1;
  }

  &.opening,
  &.opened,
  &.closing {
    z-index: 133;
  }
  &.opened {
    opacity: 1;
    aside {
      width: 60%;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }
  #dass-button-tertiary-close-newbalance {
    background-color: #E5788B !important;
    transition: background-color 0.3s ease !important;    
    &:hover {
      color: var(--theme-white) !important;
    }
  }
  #dass-button-tertiary-close-fila {
    background-color: #758396;
    transition: background-color 0.3s ease !important;    
  }
  #dass-button-tertiary-close-umbro {
    color: var(--theme-color) !important; 
  }
  #dass-button-tertiary-close-umbro:first-child {
    background-color: #737373 !important;     
  }
}
</style>
