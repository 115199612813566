import * as types from './mutation-types'

export default {
  [types.DO_LOGOUT](state) {
    state.user = {}
  },

  [types.SET_USU_EMAIL](state, payload = { email }) {
    state.user.email = payload.email
  },
  [types.SET_USU_PHONE](state, payload = { phone }) {
    state.user.phone = payload.phone
  },
  [types.SET_USER](state, payload = { user }) {
    state.user = payload.user
  },
  [types.SET_USER_ADDRESS](state, payload = { address }) {
    state.user.address = payload.address
  },
}
