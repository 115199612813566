export const ctoId = {
  Omni: 7,
  eComm: 5,
  TrabalheConosco: 4,
  RupturaLoja: 6,
  Marketplace: 8,
  DúvidaSugestão: 3,
  Solicitacao: 2,
  Reclamacao: 1,
}

export const ctoIdName = [

] 