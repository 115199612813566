<template>
  <customer-view-template>
    <div
      class="protocol-detail"
      v-if="protocol && protocol.hasOwnProperty('PRT_ID')"
    >
      <h1>{{ $t('protocolo.detailProtocol') }}</h1>
      <div class="dass-container-protocol">
        <div class="dass-header-protocol">       
          <div>
            <h2>{{ $t('protocolo.request') }}:</h2> 
            <b>{{ protocol.MOT_NOME }}</b>
          </div>
          <div>
            <h2>{{ $t('protocolo.protocolo') }}:</h2> 
            <b>{{ protocol.PRT_ID }}</b>
          </div>
          <div>          
            <h2>{{ $t('protocolo.status') }}:</h2> 
            <b  :class="`color-sit-${protocol.SIT_ID} status`">{{ $t(`situacoes.${protocol.SIT_ID}`) }}</b>
          </div>
          <div>
            <h2>{{ $t('protocolos.dataAbertura')  }}:</h2>
            <b>{{ $filters.toDateBRL(protocol.PRT_DTCRIACAO) }} - {{ $filters.toHourBRL(protocol.PRT_DTCRIACAO) }}</b>
          </div>
        </div>
        <div class="dass-data-client-protocol">       
          <div>
            <h3>{{ $t('etapas.comprador.nome') }}:</h3> 
            <b>{{ capitalizeWords(protocol.CON_NOME) }}</b>
          </div>
          <div>
            <h3>{{ $t('etapas.comprador.cpfOrCnpj') }}:</h3> 
            <b>{{ mask(protocol.CON_CPF) }}</b>
          </div>
          <div>          
            <h3>{{ $t('etapas.comprador.email') }}:</h3> 
            <b>{{ protocol.USU_EMAIL }}</b>
          </div>
          <div>
            <h3>{{ $t('etapas.comprador.telefone') }}:</h3> 
            <b>{{ maskCel(protocol.CON_FONE_CEL) }}</b>
          </div>
        </div>
        <div  
          v-for="product in products" v-bind:key="product.PRT_ID"
          class="dass-products-protocol"
        >  
         <ProductDetail :product="product"></ProductDetail> 
        </div>
        <div class="dass-history-protocol">
          <history></history>
        </div>
      </div>
    </div>
    <div v-else>
      {{ $t('erro.carregar') }}
    </div> 
  </customer-view-template>
</template>
<script>
import CustomerViewTemplate from '../components/templates/CustomerView'
import History from '../components/organisms/protocol/History'
import { mapGetters, mapActions } from 'vuex'
import capitalizeAllWords from '@/helpers/normalizers/capitalizeAllWords'
import { maskCpfOrCnpj } from '../helpers/normalizers/maskCpfOrCnpj'
import ProductDetail from '../components/organisms/protocol/productDetail.vue'
import { maskPhone } from '../helpers/normalizers/maskPhone'
export default {
  name: 'ProtocolDetailHistory',
  components: { 
    CustomerViewTemplate,
    History,
    ProductDetail
  },
  computed: {
    prt_id: function () {
      return this.$route.params.prt_id
    },
    ...mapGetters('protocol_detail', { 
      protocol: 'getProtocol',
      products: 'getProtocolItems' }),
    ...mapGetters('protocols', { protocols: 'getProtocols' }),
  },
  methods: {
    ...mapActions('protocol_detail', ['loadProtocol', 'loadProtocolHistory', 'loadProtocolItems']),
    loadData: async function () {  
      let protocol = [] 
      
      if(!this.protocol.length) {
        protocol = await this.loadProtocol({ prt_id: this.prt_id })
      } else {
        protocol = this.protocol
      }      
      await Promise.all([        
        this.loadProtocolHistory({ prt_id: this.prt_id }),
        this.loadProtocolItems(protocol),
      ])  
    },
    capitalizeWords(name) {      
      return capitalizeAllWords(name)
    },
    mask(cpf) {
      return maskCpfOrCnpj(cpf)
    },
    maskCel(cel) {
      return maskPhone(cel)
    }
  },
  created: async function () {    
    await this.loadData()
  },
}
</script>

<style lang="scss">
.protocol-detail { 
  .dass-container-protocol{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border: 2px solid var(--color-neutral__07);
    border-radius: var(--theme-border-radius);
    .dass-header-protocol {
      display: flex;      
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;
      flex-wrap: wrap;      
      padding: var(--space) var(--space__2x) 0 var(--space__2x);
      background-color: var(--color-neutral__07);      
      h2 {
        color: var(--theme-success);
        margin-bottom: 0;
        padding-bottom: 0;
      }
      b {
        font-size: 1.2rem;
      }
      .status {
        border-bottom: 3px solid var(--theme-color);
      }   
      > div {
        flex: 1 1 20%;
        min-width: 250px;
        text-align: center; 
        margin-right: var(--space__0-25x);
      }

      > div:last-child {
        margin-right: 0;
      }
    }
    .dass-data-client-protocol {
      display: flex;      
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;
      flex-wrap: wrap;      
      padding: var(--space) var(--space__2x) var(--space__2x) var(--space__2x);  
      background-color: var(--color-neutral__07);
      h3 {
        color: var(--theme-success);
        margin-bottom: 0;
        padding-bottom: 0;
      }
      b {
        font-size: 1.2rem;
      }
      .status {
        border-bottom: 3px solid var(--theme-color);
      }  
      > div {
        flex: 1 1 20%;
        min-width: 250px;
        text-align: center; 
        margin-right: var(--space__0-25x);
      }
      > div:last-child {
        margin-right: 0;
      } 
    }
    
  }
  .dass-products-protocol {
    width: 100%;
    border-bottom: 3px solid var(--color-neutral__07);     
  }
  .dass-history-protocol {
    width: 100%;
    margin-bottom: var(--space);  
  }
}
</style>
