<template>
  <div class="modal-verify-code">
    <dass-dialog
      v-if="is_modal_open"
      :title="$t('login.modals.quickAccess.titulo')"
      size="medium"
      ref="ModalVerifyCode"
      @close="toogleModal(false)"
    >
      <template #content>
        <div class="subtitulo">
          <small
          v-html="$t('login.modals.quickAccess.codeSubtitulo', {EmailOrFone: $t('dass.email') + '/' + $t('dass.telefone')}) + ': '"
          ></small>
          <b> {{ usu_email || fone }}</b>
        </div>
        <form action="" @submit="formSubmit">
          <div class="items-group" v-if="!usu_email && !fone">
            <div class="checkbox-group">
              <div class="checkbox-item">
                <input 
                  type="checkbox" 
                  id="show-email" 
                  v-model="showEmail" 
                  @change="toggleField('email')" 
                />
                <label for="show-email" class="items-checkbox">{{ $t('login.modals.quickAccess.JapossuiCodEmail') }}</label>
              </div>
            <dass-icon class="checkbox-icon" :class="brand.class_name || ''" token="icon-communication-email" size="20" />
          </div>
            <div class="input-email" v-if="showEmail">
              <dass-input-text
                type="email"
                id="input-email"
                v-model="email"
                :required="showEmail"
              />
            </div>
            <div class="checkbox-group">
              <div class="checkbox-item">
                <input 
                  type="checkbox" 
                  id="show-phone" 
                  v-model="showPhone" 
                  @change="toggleField('phone')"
                />
                <label for="show-phone" class="items-checkbox">{{ $t('login.modals.quickAccess.JapossuiCodPhone') }}</label>
              </div>
              <dass-icon class="checkbox-icon" :class="brand.class_name || ''" token="icon-socialmedia-whatsapp" size="20" />
            </div>
            <div class="input-phone" v-if="showPhone">
            <dass-input-text
              type="phone"
              id="input-phone"
              v-model="phone"
              placeholder="+55 (11) 99999-9999"
              v-mask="['+## (##) #####-####']"
              :required="showPhone"
            />
          </div>
          </div>
          <div class="form-group">
            <label for="input-code"> {{ $t('dass.codigo') }}: </label>
            <dass-input-text
              type="tel"
              id="input-code"
              v-model="code"
              :required="true"
              v-mask="{
                mask: '#######',
                tokens: {
                  '#': { pattern: /\d/ },
                },
              }"
            />
          </div>
          <div class="footer">
            <dass-button :class="this.isSafari ? `dass-button-tertiary-${this.brandName}` : ''" @click="cancelForm" type="button" variant="tertiary">
              {{ $t('dass.cancelar') }}
            </dass-button>
            <dass-button :class="this.isSafari ? `dass-button-primary-${this.brandName}` : ''" type="submit" variant="primary">
              {{ $t('dass.enviar') }}
            </dass-button>
          </div>
        </form>
      </template>
    </dass-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { useIsSafari } from '@/composables/useIsSafari'

export default {
  name: 'ModalVerifyCode',
  computed: {
    ...mapGetters('modals', {
      is_modal_open: 'getModalVerifyCodeStatus',
    }),
    ...mapGetters('session', {
      usu_email: 'getUsuEmail',
      fone: 'getUsuPhone'    
    }),
    ...mapGetters('brand', { brand: 'getBrand' }),
  },
  setup() {
    const { isSafari, brandName } = useIsSafari()
  
    return {      
      isSafari,
      brandName
    }     
  },
  methods: {
    ...mapActions('modals', {
      toogleModal: 'toogleModalVerifyCode',
    }),
    ...mapActions('toasts', {
      showToast: 'showToast',
    }),
    ...mapActions('session', ['verifyCode','setUsuPhone','setUsuEmail']),

    cancelForm: function () {
      this.$refs.ModalVerifyCode.close()
      if (this.phone || this.fone) this.setUsuPhone ('')
      if (this.usu_email || this.email) this.setUsuEmail ('')
    },

    formSubmit: async function (e) {
      e.preventDefault()
      let code = this.code
      let request_body = {
        code,
      }
      if (this.usu_email || this.usu_email !== '') {
        request_body.email = this.usu_email
      }
      if (this.email || this.email !== '') {
        request_body.email = this.email
      }
      if (this.phone) {
        request_body.fone =  this.phone.replace(/\D/g, '')
      }
      if (this.fone) {
        request_body.fone =  this.fone.replace(/\D/g, '')
      }

      const response_login = await this.verifyCode(request_body)
      if (!response_login.user || response_login.user.length < 1 && !email) {
        return this.showToast({
          variant: 'warning',
          message: this.$t('login.modals.quickAccess.clienteSemEmail'),
        })
      }
      if (response_login.token !== undefined) {
        this.$refs.ModalVerifyCode.close()
        return this.$router.push('/')
      }
    },
    toggleField(selected) {
      if (selected === 'email') {
        this.showPhone = false 
        this.phone = ''
      } else if (selected === 'phone') {
        this.showEmail = false 
        this.email = ''
      }
    }
  },
  watch: {},  
  data: () => ({
    code: '',
    email: '',
    phone: '',
    showPhone: false,
    showEmail: false    
  }),
}
</script>

<style lang="scss">
.modal-verify-code {
  .dass-dialog--contents {
    padding-top: 0;
  }
  .subtitulo {
    padding-bottom: var(--space);
  }
  label {
    font-weight: bold;
  }

  .footer {
    margin-top: var(--space__1-5x);
    display: flex;
    justify-content: space-between;
    .dass-button__primary {
      width: 250px;
    }
    .dass-button__tertiary {
      width: 250px;
    }
    .dass-button-tertiary-newbalance {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #E5788B !important;
      }
    }
    .dass-button-primary-newbalance {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #E5788B !important;
      }
    }
    .dass-button-tertiary-umbro {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #737373 !important;
      }
    }
    .dass-button-primary-umbro {
      transition: border 0.3s ease !important;
      &:hover {
        border: 2px solid #737373 !important;
      }
    }
    .dass-button-tertiary-fila {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #758396 !important;
      }
    }
    .dass-button-primary-fila {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #758396 !important;
      } 
    } 
  }
}

.theme-umbro {
  .dass-dialog--control {
    background-color: var(--theme-color);
    color: var(--theme-white);
  }
  .subtitulo b {
    color: var(--theme-white) !important;
    text-decoration: underline !important;
  }
  .dass-link {
    color: var(--theme-white) !important;
  }
  .dass-button__tertiary {
    color: var(--theme-white) !important;
    text-decoration: underline !important;
  }
  .dass-button__primary {
    background-color: var(--theme-white) !important;
    color: var(--theme-color) !important;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #758396;
    }
  }
}
</style>
