export function removeProperties(obj, default_obj) {
  if (Array.isArray(obj)) {
    return obj.map(item => removeProperties(item, default_obj[0]))
  }

  Object.keys(obj).forEach(key => {
    if (!Object.prototype.hasOwnProperty.call(default_obj, key)) {
      delete obj[key]
    } else if (typeof obj[key] === 'object') {
      removeProperties(obj[key], default_obj[key])
    }
  })
}