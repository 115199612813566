<template>
    <div class="dass-card-selected-items-container" :class="`dass-card-selected-items-container-${brandName}`">
      <div class="dass-container-cards">
        <div v-if="isChangeColor" class="dass-icon-view-item">
          <dass-icon token="icon-action-zoom_in" size="30" @click="showModalViewItem"></dass-icon>
        </div>               
        <div 
          v-for="(items, index) in listItems" :key="index"  
          class="dass-card-items"        
          :class="!isChangeColor && items.EST_SLD == 0 ? 'dass-content-card-unavailable' :
           isChangeColor ? 'dass-card-items-colors dass-change-colors' : !isChangeColor ? 'dass-change-size' : ''"
          :style="isChangeColor ? createGradientOfColors(items.COR_CODHEXA) : ''"
          @click="(event) => handlerClick(event, items)"
        >
          <b v-if="!isChangeColor" >{{ items.GRA_TAMANHO }}</b>
          <img v-if="isChangeColor" :src="items.LINK_IMAGEM" :alt="items.PRO_NOME" />          
        </div>        
      </div>
      <div class="dass-label-name">
        {{ labelNameCard }}
      </div>
    </div> 
    <ModalViewImg
      v-if="showModalView"
      :imgSrc= "srcShowModalViewItem"  
      :showModal= "showModalView"
      @closeModal="showModalView = !showModalView"
    />
</template>

<script>
import { mapGetters } from 'vuex'
import ModalViewImg from './ModalViewImg.vue'
import { createGradientOfColor } from '../../helpers/functions/createGradientOfColor'

export default {
  name: 'CardSelectedItems',
  components: { ModalViewImg },
  props: {
    listItems: {
      type: Array,
      required: true
    },
    labelNameCard: {
      type: String,
      default: ''
    },
    isChangeColor: {
      type: Boolean,
      default: false
    }
  },
  emits: ['selectItem'],
  data() {
    return {
      brandName: '',
      seletedItem: false,
      srcShowModalViewItem: '',
      showModalView: false
    }
  },
  computed: {
    ...mapGetters('brand', { brand: 'getBrand' }),
  },
  methods: {
    handlerClick(event, items) {
      if(items.EST_SLD == 0) return
      this.$emit('selectItem', items) 
      const element =  event.target      
      const parentElement = this.isChangeColor ? element.closest('.dass-change-colors') : element.closest('.dass-change-size')
      const allElements =  this.isChangeColor ?  document.querySelectorAll('.dass-change-colors') : document.querySelectorAll('.dass-change-size')
      allElements.forEach((element) => {
        element.classList.remove('dass-card-items-selected')
      })     
      if (parentElement) {
        parentElement.classList.add('dass-card-items-selected')
      }
    },
    createGradientOfColors(color) {
      return createGradientOfColor(color)
    },
    showModalViewItem() {      
      const item = document.querySelector('.dass-card-items-selected img')
     
      if (item) {        
        this.srcShowModalViewItem = item.src
        this.showModalView = true        
      } else {
        this.$ShowAlert({
          variant: 'warning',
          message: this.$t('etapas.produto.selecioneCor'),
          hideDelay: 3000
        })
      }
    }
  },
  created() {
    this.brandName = this.brand.name.replace(/\s/g, '').toLowerCase()    
  } 
}
</script>

<style>  
  .dass-card-selected-items-container {
    position: relative;
    display: flex;
    margin-top: 0.7rem;
    border: 2px solid var(--theme-gray);
    border-radius: var(--border-radius-lg);
    min-height: 4rem; max-height: 7rem;
    min-width: 100%;
    padding: 0.7rem; 
  }
  .dass-card-selected-items-container-umbro {
    border-radius: 0 !important;
  }
  .dass-card-selected-items-container-fila {
    border-radius: 0 !important;
  }
  .dass-container-cards {
   display: flex;
   overflow-y: auto;
   scroll-behavior: smooth;   
   gap: 0.5rem;
   flex-wrap: wrap;
   max-height: 100%; 
   padding: 0.5rem;  
  }  
  .dass-icon-view-item {
    position: absolute;
    top: 0.5rem;
    right: 1.2rem;
    z-index: 99;
    cursor: pointer;
    &:hover {
      transform: scale(1.3);
      transition: transform 0.2s ease-in-out;
    }
  }
  .dass-card-items {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--theme-gray);
    border-radius: var(--border-radius-md);
    width: 50px;
    height: 50px;    
    &:hover {
      cursor: pointer;
      box-shadow: var(--ds-shadow-overlay, -4px 8px 16px -4px rgba(9,30,66,0.25),0 0 1px rgba(9,30,66,0.31));
      transition: box-shadow 0.2s ease 0s;
    }
    > img {
      width: 100%;
      height: auto;
      padding: 0.1rem;
    }
  }
  .dass-content-card-unavailable {
    border: 1px solid var(--theme-gray);
    border-radius: var(--border-radius-md);
    width: 50px;
    height: 50px;
    background-color: #F1F1F1;
    position: relative;
    &:hover {
      cursor: default;
      box-shadow: none;

    }
  }
  .dass-content-card-unavailable::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: linear-gradient(to top left, transparent calc(50% - 1px), var(--theme-gray), transparent calc(50% + 1px));
  }
  .dass-label-name {
    position: absolute;
    top: -5px;
    left: 10px;
    z-index: 10;
    padding: 0.1rem 1rem;
    background: var(--theme-white);
    line-height: 0.8rem; font-size: 1rem;
    font-weight: bold;
    color: var(--theme-gray);
  }
  .dass-card-items-selected {
    outline: 2px solid var(--color-primary);
    outline-offset: 2px;
    box-shadow: 0 0 0 2px var(--color-primary);
    transition: box-shadow 0.2s ease 0s;
  }
  .dass-card-items-colors {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
</style>