import { computed } from 'vue'
import { useStore } from 'vuex'


export function useIsSafari() {
  const store = useStore()
  const brand = computed(() => store.getters['brand/getBrand'])    
  const isSafari = computed(() => /^((?!chrome).)*safari/i.test(navigator.userAgent))
  const isMac = computed(() => navigator.userAgent.includes('Macintosh'))
  const brandName = computed(() => brand.value.name.replace(/\s/g, '').toLowerCase()) 

  return { 
    isSafari: isSafari.value,
    brandName: brandName.value,
    isMac: isMac.value
  } 
}