<template>
      <div v-for="(dataItens, index) in itens" :key="index" class="dass-container-product-upload">
          <div class="dass-container-product-data">
              <div v-if="dataItens.thumbnail" class="dass-thumbnail">
                <img :src="dataItens.thumbnail" :alt="dataItens.produtoNome"/>          
              </div>
              <div class="dass-details-product-container">
                <div class="dass-data-product">
                  <div class="dass-title-product">
                    <b>{{ $t('etapas.produto.tituloProdutoTro') }}:</b>
                  </div>    
                  <div class="dass-data">
                    <div>
                      <span>{{ $t('etapas.produto.produto') }}: </span>
                      <b>{{ dataItens.produtoNome }}</b>
                    </div>
                    <div>
                      <div>
                        <div>
                          <span>{{ $t('etapas.produto.cor') }}: </span>
                          <b>{{ dataItens.produtoCor }}</b>
                        </div>
                        <div>
                          <span>{{ $t('etapas.produto.tamanho') }}: </span>
                          <b>{{ dataItens.tamanhoTroca ? dataItens.tamanhoTroca : dataItens.gradeTamanho }}</b>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
          </div>
          <div v-if="dataItens.isRequiredImageToProduct" class="dass-container-product-pictures">
              <ProductUploadImage />
          </div>
          <div v-if="dataItens.isRequiredImageToProduct" class="dass-container-preview-images">
              <PicturePreviewThumbnail  />
          </div>  
      </div> 
</template>
<script>
import ProductUploadImage from '@/components/molecules/ProductUploadImage'
import PicturePreviewThumbnail from '@/components/molecules/PicturePreviewThumbnail'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductDataImage',
  components: { ProductUploadImage, PicturePreviewThumbnail },
  
  computed: {
    ...mapGetters('create_protocol', {dados_item: 'getDadosItens'}),
    itens() {     
      return Array.isArray(this.dados_item) ? this.dados_item : [this.dados_item]
    },    
  }, 
}

</script>
<style scoped>
.dass-container-product-upload {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.dass-container-product-data {
  display: flex;
  flex-direction: row;  
  align-items: flex-start;
  flex: 1;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 430px) {
    flex-direction: column;
  }
}

.dass-thumbnail { 
  > img {
    max-width: 150px;
    height: auto;
    margin-right: var(--space);
  }
}
.dass-title-product {
  > b {
    color: var(--color-status--success); 
  }
}

.dass-data b{
  margin-left: var(--space__0-25x);
}
.dass-container-product-pictures {
  flex: 1;
  margin-bottom: var(--space__0-5x); 
}
.dass-container-preview-images {
  width: 100%;
}
@media (max-width: 1000px) {
  .dass-container-product-data,
  .dass-container-product-pictures {
    width: 100%;
  }
}
</style>