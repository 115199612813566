export function maskPhone(phone) {  
  if(!phone) return
  let phoneMask = phone.replace(/\D/g, '')
  if (phoneMask.length === 10) {
    phoneMask = phoneMask.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3')
  } else if (phoneMask.length === 11) {
    phoneMask = phoneMask.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3')
  } else if (phoneMask.length === 12) {
    phoneMask = phoneMask.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/g, '+$1 ($2) $3-$4')
  } else if (phoneMask.length === 13) {
    phoneMask = phoneMask.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/g, '+$1 ($2) $3-$4')
  }
  
  return phoneMask
}