export const telefoneValidator = function (telefone) { 
  telefone = telefone.replace(/\D/g, '')
  return /^\d{10,11}$/.test(telefone) 
}

export const validatorPhoneWithDDI = function validarTelefone(telefone) {
  const regex = /^\+?([0-9]{1,3})\s?\(?([0-9]{2})\)?[-. ]?([0-9]{4,5})[-. ]?([0-9]{4})$/  
  const partes = telefone.match(regex)

  if (!partes) {
    return false
  }

  const ddi = partes[1]
  const ddd = partes[2]
  const telefoneFixo = partes[3]
  const telefoneCelular = partes[4]

  if (ddi.length > 3 || ddd.length !== 2 || telefoneFixo.length < 4 || telefoneCelular.length < 4) {
    return false
  }

  return true
}
