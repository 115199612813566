import { onlyNumbersInString } from './onlyNumbersInString'
import { cpfValidator } from './cpfValidator'

export const maskPix = (pixKey) => {
  let maskPix = pixKey.replace(/\D/g, '') || ''
  if(onlyNumbersInString(maskPix)) {
    if(maskPix.length == 11 || maskPix.length == 10) {          
      if(maskPix.length === 11 && cpfValidator(maskPix)) {                       
        maskPix = maskPix.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')            
      } else if(maskPix.length == 11) {            
        maskPix = maskPix.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')            
      } else {           
        maskPix = maskPix.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')            
      }
    } else if(maskPix.length > 11 && maskPix.length <= 14) {              
      maskPix = maskPix.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    }       
  }       
  return maskPix
}