import { clientAPI } from '../configs/configs'
import * as env from '../configs/configs'
import { removeEmptyFields } from '../helpers/normalizers/removeEmptyFields'

const urlBase = env.urlGeneral + '/returns'
export default class Orders {
  static async loadOrders() {
    return new Promise((res, rej) => {
      try {
        clientAPI.post(`${urlBase}/orders`).then((response) => {
          res(response.data)
        })
      } catch (err) {
        rej(err)
      }
    })
  }

  static async getBonus(filters) {
    let defaultFilters = {
      bonCodigo: '',
      bonPercentual: '',
      bonStatus: '' 
    }

    let requestFilters = Object.assign({}, defaultFilters, filters)
    requestFilters = removeEmptyFields(requestFilters)
    
    const request_query_string = new URLSearchParams(requestFilters)
    
    return new Promise((res, rej) => {
     
      clientAPI
        .get(`${urlBase}/bonus`, {params: request_query_string})
        .then((response) => {
          res(response.data)
        })
        .catch((error) => {
          rej(error)
        })
     
    })
  } 
}
