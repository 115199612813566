export default {
  current_step: 1,
  data: {
    subjects: [],
    reasons: [],
    subReasons: [],
    allSubReasons: [],
  },
  dadosGerais: {},
  bankData: {},
  dadosItens: [],
  picture_list: [],
  itensToAddBankData: [],
  dataConsumer: [],
  banks: []
}
