import { clientAPI } from '../configs/configs'
import * as env from '../configs/configs'
import { removeEmptyFields } from '../helpers/normalizers/removeEmptyFields'

const urlBase = env.urlGeneral + '/returns'
export default class Protocols {
  static async getProtocols(filters) {
    const default_filters = {
      usu_login: '',
      usu_email: '',
      prt_id: '',
    }

    let request_body = Object.assign({}, default_filters, filters)
    request_body = removeEmptyFields(request_body)

    return new Promise((res, rej) => {
      try {
        clientAPI
          .post(`${urlBase}/protocols/b2c`, request_body)
          .then((response) => {
            res(response.data)
          })
      } catch (err) {
        rej(err)
      }
    })
  }
  static async getProtocolHistory(filters) {
    const default_filters = {
      prt_id: '',
    }
    const request_body = Object.assign({}, default_filters, filters)

    return new Promise((res, rej) => {
      try {
        clientAPI
          .get(`${urlBase}/protocols/b2c/historic/${request_body.prt_id}`)
          .then((response) => {
            res(response.data)
          })
      } catch (err) {
        rej(err)
      }
    })
  }
  static async getProductBalanceB2C(filters) {
    const default_filters = {
      pro_codigo: '',
      gra_tamanho: '',
      cor_nome: '',
      pro_modelo: ''
    }
    let request_body = Object.assign({}, default_filters, filters)
    request_body = removeEmptyFields(request_body)
    
    return new Promise((res, rej) => {
      try {
        clientAPI
          .post(`${urlBase}/protocols/product/balance/b2c`, request_body)
          .then((response) => {
            res(response.data)
          })
      } catch (err) {
        rej(err)
      }
    })
  }

  static async getProtocolItems(fields) {
    const default_fields = {
      prt_id: '',
    }

    const request_body = Object.assign({}, default_fields, fields)

    return new Promise((res, rej) => {
      try {
        clientAPI
          .get(`${urlBase}/protocols/b2c/items/${request_body.prt_id}`)
          .then((response) => {
            res(response.data)
          })
      } catch (err) {
        rej(err)
      }
    })
  }

  static async getProtocolItemsRuptura(fields) {
    const default_fields = {
      prt_id: '',
    }

    const request_body = Object.assign({}, default_fields, fields)

    return new Promise((res, rej) => {
      try {
        clientAPI
          .get(`${urlBase}/stockouts/b2c/ecomm/${request_body.prt_id}`)
          .then((response) => {
            res(response.data)
          })
      } catch (err) {
        rej(err)
      }
    })
  }
}
