import Orders from '@/services/orders'
import * as types from './mutation-types'
import store from '../..'

export const loadOrders = async ({ commit }) => {
  const brand = store.getters['brand/getBrand'] || {}

  const response = await Orders.loadOrders()
  let orders = response.productBalance || []
  const mar_cods = brand.mar_cods || []
  orders = orders.filter((order) => {
    return mar_cods.includes(order.MAR_CODIGO)
  })

  orders = orders.map((order) => {
    const uniqueKey = [
      order.FAT_SEQUENCIA,
      order.ITE_SEQUENCIA,
      order.PRO_CODIGO,
      order.GRA_TAMANHO,
      order.NUMERONF,
      order.COR_NOME,
    ].filter(Boolean).join('_')
  
    return { ...order, UNIQUE_KEY: uniqueKey }
  })
  commit(types.FILL_ORDERS, { orders })
}

export const toggleSidebar = async ({ commit }, opened) => {
  const opened_store = store.getters['orders/getSidebarOpened'] || false

  if (typeof opened == 'undefined') opened = !opened_store
  commit(types.TOGGLE_SIDEBAR, { sidebar_opened: opened })
}

const generateUID = function () {
  let rnd = Math.random() * (10000 - 1) + 1
  rnd = rnd + ''
  rnd = rnd.replace('.', '')
  let uid = `${rnd}`
  return uid
}

export const loadBonus = async ({ commit }, filters) => {   
  let response = await Orders.getBonus(filters)  
  const bonus = response.bonus || []
  
  return bonus
}
