<template>
  <customer-view-template>
    <div v-if="usu_email" class="protocolos">
      <h1>{{ $t('protocolos.titulo') }}</h1>
      <div
        v-if="
          protocols.length > 0 &&
          protocols[0] &&
          protocols[0].hasOwnProperty('PRT_ID')
        "
      >
        <div
          class="protocol"
          v-for="protocol in protocols"
          v-bind:key="protocol.PRT_ID"
        >
          <div class="action">
            <router-link
              :to="`/protocol/${protocol.PRT_ID}`"
              :title="$t('dass.visualizarDetalhes')"
            >
              <dass-icon
                token="icon-action-visibility"
                size="25"
                color="var(--color-secondary--blue__light)"
              >
              </dass-icon>
            </router-link>
          </div>
          <router-link
            :to="`/protocol/${protocol.PRT_ID}`"
            :title="$t('dass.visualizarDetalhes')"
          >
            <h3 :class="`color-sit-${protocol.SIT_ID}`">
              {{ protocol.PRT_ID }} -
              <span>
                {{ $t(`situacoes.${protocol.SIT_ID}`) }}
              </span>
            </h3>
          </router-link>

          <div class="detalhe">
            <b>{{ $t('etapas.produto.numeroNF') }}:</b
            ><span> {{ protocol.PRT_NFISCAL }}</span>
          </div>
          <div class="detalhe">
            <b>{{ $t('protocolos.dataAbertura') }}:</b>
            <span>
              {{ $filters.toDateBRL(protocol.PRT_DTCRIACAO) }} -
              {{ $filters.toHourBRL(protocol.PRT_DTCRIACAO) }}
            </span>
          </div>
        </div>
      </div>
      <div v-else>{{ $t('erro.semDados') }}</div>
    </div>
    <div v-else>{{ $t('erro.semDados') }}</div>
  </customer-view-template>
</template>
<script>
import CustomerViewTemplate from '../components/templates/CustomerView'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Protocols',
  components: { CustomerViewTemplate },
  methods: {
    ...mapActions('protocols', ['loadProtocols']),
  },
  computed: {
    ...mapGetters('protocols', { protocols: 'getProtocols' }),
    ...mapGetters('session', {
      usu_email: 'getUsuEmail',
      phone: 'getUsuPhone'
    }),   
  },
  created: function () {    
    if (!this.usu_email && !this.phone) return
    this.loadProtocols()
  },  
}
</script>

<style lang="scss">
.protocolos {
  text-align: left;

  h3 {
    margin: 0;
    margin-bottom: var(--space__0-5x);
    width: 100%;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
  }
  .protocol {
    width: 24%;
    padding: var(--space__0-75x);
    border: 1px solid var(--color-neutral__30);
    border-radius: var(--border-radius-md);
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--space);
    margin-right: var(--space);
    position: relative;
    @media (min-width: 576px) {
      min-width: 450px;
    }
    @media (max-width: 576px) {
      width: 100%;
      margin-right: 0;
    }
    .action {
      position: absolute;
      right: var(--space__0-25x);
      top: var(--space__0-25x);
    }
    .detalhe {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      > b {
        min-width: 35%;
        text-align: right;
        margin-right: var(--space__0-5x);
        @media (max-width: 576px) {
          min-width: 45%;
        }
      }
    }
  }  
}
</style>
