<template>    
    <div class="dass-data-products-protocol">  
      <div class="dass-thumbnail">
        <img :src="product.LINK_IMAGEM" :alt="product.PRO_RESUMIDO"/>  
      </div>
      <div>
        <h3>{{ $t('etapas.produto.produto') }}:</h3> 
        <b>{{ product.PRO_RESUMIDO }}</b>
      </div>
      <div>          
        <h3>{{ $t('dass.productCode') }}:</h3> 
        <b>{{ product.PRO_CODIGO }}</b>
      </div>
      <div>
        <h3>{{ $t('dass.quantity') }}:</h3> 
        <b>{{ product.ITP_QTDEV }}</b>
      </div>
      <div>
        <h3>{{ $t('etapas.produto.valueProduct') }}:</h3> 
        <b>{{ $filters.toMoney(product.ITF_VLUNIT) }}</b>
      </div>
    </div>   
</template>

<script>

export default {
  name: 'ProductDetail',
  props: {
    product: {
      type: Object,
      required: true
    }
  }
}

</script>

<style lang="scss">
.dass-data-products-protocol {
    display: flex;      
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
    text-align: center;
    padding: var(--space) var(--space__2x) var(--space__2x) var(--space__2x); 
    h3 {
      color: var(--theme-success);
      margin-bottom: 0;
      padding-bottom: 0;
    }
    b {
      font-size: 1.2rem;
    }
    > div {      
      margin-right: var(--space);
      flex: 1 1 18%;
      min-width: 200px;
    }

    > div:last-child {
      margin-right: 0;
    }
    .dass-thumbnail { 
      > img {
        max-width: 150px;
        height: auto;       
      }
    }
}    
</style>