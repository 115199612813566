export function maskCpfOrCnpj(val) {
  if(!val) return
  const valFormated = val.replace(/[^0-9]/g, '')
  if (valFormated.length == 11) {
    return val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
  } else if (valFormated.length == 14) {
    return val.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
  } else {
    return val
  }
}