<template>
    <div class="modal-shopping-voucher">
      <dass-dialog
        v-if="showModalShoppingVoucher"
        title=" " 
        size="medium"
        ref="ModalShoppingVoucher"
        class="dass-dialog--shopping-voucher"
        @close="closeModal"
      >
        <template #content>            
            <div class="dass-title">
              <h3>{{ this.$t('dass.requestRefund') }} </h3>
            </div>
            <div class="dass-subtitle">
              {{ this.$t('dass.requestRefundDescription') }}
            </div> 
            <div class="dass-actions">
                <div class="dass-card-content-container">
                    <div class="dass-card-content">
                       <span>{{  this.$t('dass.shoppingVoucher') + ' + ' + percentageBonus + '% ' + this.$t('dass.bonusShoppingVoucher') }}</span>
                       <span>{{  this.$filters.toMoney(shoppingVoucherValue)  }}</span>                    
                    </div>
                    <dass-button :class="this.isSafari ? `dass-button-primary-${this.brandName}` : 'dass-button-shopping-voucher'" @click="acceptShoppingVoucher" type="button" variant="primary">
                        {{ $t('dass.receiveShoppingVoucher') }}
                    </dass-button>
                </div>
                <dass-button :class="this.isSafari ? `dass-button-tertiary-${this.brandName}` : ''" @click="closeModal" type="button" variant="tertiary">
                  {{ $t('dass.ProceedToRefund') }}
                </dass-button>
            </div>
            <div class="dass-message-refund">
              <small>{{ $t('dass.messageRefund') }}</small>
            </div>             
        </template>
      </dass-dialog>
    </div>
  </template>
  
<script>
import { mapGetters, mapActions } from 'vuex'
import { useIsSafari } from '@/composables/useIsSafari'
  
export default {
  name: 'ModalShoppingVoucher',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    percentageBonus: {
      type: Number,
      default: 0,
    },
    shoppingVoucherValue: {
      type: Number,
      default: 0,
    },
  },
  emits: ['closeModal', 'acceptShoppingVoucher'],  
  setup() {
    const { isSafari, brandName } = useIsSafari()
    
    return {
      isSafari,
      brandName
    }
  },
  computed: {
    showModalShoppingVoucher() {      
      return this.showModal
    }
  },
  methods: {
    closeModal() {          
      this.$emit('closeModal')      
    },  
    acceptShoppingVoucher() {
      this.$emit('acceptShoppingVoucher')
    }    
  },    
}
</script>
  
<style lang="scss">
  .modal-shopping-voucher {
    .dass-dialog--close {
      color: var(--background-color-button-primary-shopping-voucher);
      padding: 0 !important;
      height: 50px;
      width: 50px;
    }
    .dass-dialog--header {
      padding-top: 0 !important;
      padding-right: 0 !important;
    }
    .dass-dialog--control {
      border-radius: 10px !important;
    }    
    .dass-dialog--contents {
      padding-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-right: 1rem;
      margin-left: 1rem;
    }    
    .dass-subtitle {           
      padding-bottom: var(--space);
    }
    label {
      font-weight: bold;
    }
    .dass-message-refund {
        margin-bottom: var(--space__0-5x);
        margin-top: var(--space__0-5x);
        line-height: normal !important;
    }
    .dass-actions {
      margin-top: var(--space__1-5x);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: var(--space);
      .dass-card-content-container {
        border: 1px solid #E9E9E9;
        border-radius: var(--theme-border-radius);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: auto;        
        .dass-card-content {     
          padding: var(--space);     
          text-transform: uppercase;
          display: flex;
          flex-direction: column;
          align-items: center; 
          row-gap: var(--space__0-5x);  
        }
    }      
    
      .dass-button__primary {
        width: 100%;
        border-radius: var(--theme-border-radius);
        background-color: var(--background-color-button-primary-shopping-voucher) !important;
      }
      .dass-button__tertiary {
        width: 100%;
        text-decoration: underline ;
      }
      .dass-button-tertiary-newbalance {
        transition: background-color 0.3s ease !important;
        &:hover {
          background-color: #E5788B !important;
        }
      }
      .dass-button-primary-newbalance {
        transition: background-color 0.3s ease !important;
        &:hover {
          background-color: #E5788B !important;
        }
      }
      .dass-button-tertiary-umbro {
        transition: background-color 0.3s ease !important;
        &:hover {
          background-color: #737373 !important;
        }
      }
      .dass-button-primary-umbro {
        transition: border 0.3s ease !important;
        &:hover {
          border: 2px solid #737373 !important;
        }
      }
      .dass-button-tertiary-fila {
        transition: background-color 0.3s ease !important;
        &:hover {
          background-color: #758396 !important;
        }
      }
      .dass-button-primary-fila {
        transition: background-color 0.3s ease !important;
        &:hover {
          background-color: #758396 !important;
        } 
      }
    }
  }
  .theme-umbro {
    .dass-dialog--control {
      background-color: var(--theme-color);
      color: var(--theme-white);
    }
    .dass-link {
      color: var(--theme-white) !important;
    }
    .dass-button__tertiary {
      color: var(--theme-white) !important;
      text-decoration: underline !important;
    }
    .dass-button__primary {
      background-color: var(--theme-white) !important;
      color: var(--theme-color) !important;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #758396;
      }
    }
  }
</style>
  