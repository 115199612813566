import store from '../../store'
import tokenValidate from './tokenValidate'
import { showError } from '../../store/modules/toasts/actions'
import Cookies from 'js-cookie'

const isLogged = async (to, from, next) => {
  if (to.meta.freeAccess) return next()

  const session = store.getters['session/getSession'] || {}
  
  const hasUser = session.hasOwnProperty('user') && (session.user.hasOwnProperty('email') || session.user.hasOwnProperty('phone')) && !!session.user.email

  if (!hasUser) {
    const cookie = Cookies.get('usu_email')
    if (cookie) {
      await store.dispatch('session/loadSessionFromCookie')
    } else {
      showError('Houve um erro ao carregar sua sessão faça login novamente.', 'warning')
      return next({ path: '/login' })
    }
  }

  return next()
}

export default isLogged