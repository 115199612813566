<template>
  <div class="dass-card-auxiliary-message">
    <small>{{ message }}</small>
  </div>
</template>

<script>
export default {
  name: 'AuxiliaryMessage',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
}

</script>

<style>
.dass-card-auxiliary-message {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--theme-border-radius);
  background-color: #F4F4F4;
  padding: 1rem;
  width: 100%;
  height: auto;
}

</style>