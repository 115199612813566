export const autoSelectedItemInList = (fieldToSelected, list) => {
  if(!list || list.length == 0 || !fieldToSelected) return list
  const listSelected = list.map((item) => {
    if (item.value == fieldToSelected) {
      item.selected = true
    }
    return {
      ...item
    }
  })
  return listSelected
}