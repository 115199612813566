import * as types from './mutation-types'

export default {
  [types.FILL_ORDERS](state, payload = { orders }) {
    state.orders = payload.orders
  },  
  [types.TOGGLE_SIDEBAR](state, payload = { sidebar_opened }) {
    state.sidebar_opened = payload.sidebar_opened
  },
  [types.SET_SELECTED_ORDERS](state, payload = { selectedOrders }) {
    state.selectedOrders = payload.selectedOrders
  },
}
