export default {
  user: {
    email: '',
    cpf: '',
    nome: '',
    telefone: '',
    phone: '',
    address: {},
  },
}
