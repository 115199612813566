<template>
  <customer-view-template>
    <div class="dass-stepper-container">
      <stepper />
      <div class="dass-stepper-components">      
        <BuyerData ref="BuyerData" @editModeAction="editMode" />      
        <ProductDataImage ref="ProductDataImage" />      
      </div>
      <hr>
      <div class="dass-actions-finish">
        <div class="dass-actions-finish-container">
          <dass-button        
            :class="this.isSafari ? `dass-button-tertiary-${this.brandName} button` : 'button'"
            :id="`dass-button-tertiary-close-${this.brandName}`"
            variant="tertiary"
            type="button"
            @click="() => {
              this.$router.go(-1)
            }"
            >
            <dass-icon token="icon-navigation-cancel" size="30"></dass-icon>
            {{ $t('dass.cancelar') }}
          </dass-button>
          <dass-button @click="verifyDataToConfirm" :disabled="isEditMode" :class="this.isSafari ? `dass-button-primary-${this.brandName} button` : 'button'" variant="primary" type="submit">
            {{ $t('etapas.comprador.confirmar') }}
            <dass-icon
              token="icon-navigation-arrow_forward_ios"
              size="30"
            ></dass-icon>
          </dass-button>
        </div>
      </div>
    </div>
  </customer-view-template>
</template>
<script>
import CustomerViewTemplate from '../components/templates/CustomerView'
import Stepper from '../components/organisms/customer_view_template/Stepper'
import { useIsSafari } from '@/composables/useIsSafari'
import BuyerData from '../components/molecules/BuyerData'
import ProductDataImage from '../components/molecules/ProductDataImage'
import { mapActions, mapGetters } from 'vuex'
import { useSelectedOrderFromLocalStorage } from '@/composables/useSelectedOrderFromLocalStorage'

export default {
  name: 'StepperComponents',
  props: {
    ecommerce: Boolean,
  },
  components: {
    CustomerViewTemplate,
    Stepper,
    BuyerData,
    ProductDataImage    
  },  
  setup() {
    const { isSafari, brandName } = useIsSafari()
    const { clearSelectedOrders } = useSelectedOrderFromLocalStorage()
    return {      
      isSafari,
      brandName,
      clearSelectedOrders
    }     
  },
  data() {
    return {
      isLoading: false,
      idProtocol: '',  
      isEditMode: false        
    }
  },
  computed: {    
    ...mapGetters('session', ['getUsuEmail', 'getUser', 'getUserAddress']),
    ...mapGetters('brand', { marca: 'getBrand' }), 
    ...mapGetters('create_protocol', {
      dados_item: 'getDadosItens',
      getPictureList: 'getPictureList'
    }), 
      
  },
  methods: {     
    ...mapActions('create_protocol', [
      'setCurrentStep',
      'generateProtocol',
      'uploadFileList',
      'clearDataProtocol',
      'clearPicturesList'
    ]),
    ...mapActions('toasts', {
      showToast: 'showToast',
    }),    
    async createProtocol() {
      let alert_options = {}
      
      try {
        if(this.isLoading) return
        let hideDelay = 5000
        this.isLoading = true       
        const response = await this.generateProtocol()
        alert_options = this.alertMessageHandlerCreate(
          response.message,
          hideDelay
        )
        
        this.$ShowAlert(alert_options)
        if(alert_options.prt_id) this.copyToClipboard()       
        this.clearDataProtocol()     
        this.clearSelectedOrders()  
               
        if(this.getPictureList.length > 0) {
          const responses_pictures = await this.uploadFileList(alert_options.prt_id) 
          this.toastMessageHandlerUploadFile(responses_pictures, hideDelay)
        }
        this.clearPicturesList()  
        setTimeout(() => {
          window.location.pathname = '/protocolos'
        }, alert_options.hideDelay)      
      } catch (error) { 
        const detail = error.detail || ''
        const erroMessage = error.message || error.msg ||  error
        this.clearSelectedOrders()  
        this.showToast({
          variant: 'error',
          message: erroMessage  + '<br><br>' + detail,
          hideDelay: 3500
        })     
        setTimeout(() => {
          window.location.pathname = '/home' 
          this.isLoading = false   
        },3500)         
      }
    },    
    editMode(edit) {
      this.isEditMode = edit
    },
    alertMessageHandlerCreate: function (response_message, hideDelay) {
      let protocol_cod = ''
      let alert_variant = 'success'
      let alert_title = 'Msg padrão'
      let alert_message = ''
      let alert_submessage = ''
      response_message = response_message
      if (response_message.includes('gerado com sucesso')) {
        alert_title = this.$t('alerts.protocoloGerado.titulo')
        protocol_cod = response_message.split('(')[1]
        protocol_cod = protocol_cod.split(')')[0]

        alert_message = this.$t('alerts.protocoloGerado.subTitulo')
        alert_submessage = `${this.$t(
          'alerts.protocoloGerado.extra'
        )}: <b>${protocol_cod}  </b> 
        <svg id="dass-copy-content-success" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="30px" height="30px"><path d="M 18.5 5 C 15.480226 5 13 7.4802259 13 10.5 L 13 32.5 C 13 35.519774 15.480226 38 18.5 38 L 34.5 38 C 37.519774 38 40 35.519774 40 32.5 L 40 10.5 C 40 7.4802259 37.519774 5 34.5 5 L 18.5 5 z M 18.5 8 L 34.5 8 C 35.898226 8 37 9.1017741 37 10.5 L 37 32.5 C 37 33.898226 35.898226 35 34.5 35 L 18.5 35 C 17.101774 35 16 33.898226 16 32.5 L 16 10.5 C 16 9.1017741 17.101774 8 18.5 8 z M 11 10 L 9.78125 10.8125 C 8.66825 11.5545 8 12.803625 8 14.140625 L 8 33.5 C 8 38.747 12.253 43 17.5 43 L 30.859375 43 C 32.197375 43 33.4465 42.33175 34.1875 41.21875 L 35 40 L 17.5 40 C 13.91 40 11 37.09 11 33.5 L 11 10 z"/></svg>
        <br>`
        hideDelay = 7500
      }
      this.idProtocol = protocol_cod
      let options = {
        message: alert_message,
        title: alert_title,
        submessage: alert_submessage,
        variant: alert_variant,
        prt_id: protocol_cod,
        hideDelay,
      }

      return options
    },
    copyToClipboard() {
      setTimeout(() => {
        const svgElement = document.getElementById('dass-copy-content-success')          
        svgElement.addEventListener('click', () => {            
          const subMessage = document.getElementsByClassName('subtexto')
          let smallElement = subMessage[0].querySelector('small')
          if (!smallElement) {
            smallElement = document.createElement('small')
            subMessage[0].appendChild(smallElement)
          }
          navigator.clipboard.writeText(this.idProtocol)
            .then(() => {              
              smallElement.innerText = 'Copiado com sucesso!'
              smallElement.style.color = 'green'
            })
            .catch((err) => {              
              smallElement.innerText =  `Erro ao copiar: ${err}`
              smallElement.style.color = 'red'
            })
        })          
      }, 150)
    },
    toastMessageHandlerUploadFile: function (responses) {
      let options = {
        variant: 'warning',
        message: this.$t('erro.errorInSendPhotos'),
      }
      responses.forEach((response) => {
        let data = response.data || ''
        if (!data.hasOwnProperty('msg')) return
        if (!data.msg.hasOwnProperty('message')) return
        if (data.msg.message.includes('efetuado com sucesso')) {
          options.message = this.$t('etapas.fotos.uploadSucesso')
          options.variant = 'success'
        }
        this.showToast(options)
      })
    },  
    verifyDataToConfirm() {        
      if(this.verifyBuyerData() && this.verifyProductHasDataImage()) {
        this.createProtocol()
      }
    },    
    verifyBuyerData() {
      const buttonFormBuyerData = document.getElementById('dass-button-confirm-form-buyer-data')
      buttonFormBuyerData.click()     
      const isValidBuyerData = this.$refs.BuyerData.validateDataRequired()
      if(!isValidBuyerData) {        
        return false
      }
      return true
    },
    checkIfAnyItemRequireImage() {
      const listItems = Array.isArray(this.dados_item) ? this.dados_item : [this.dados_item]
      return listItems.some(item => item.isRequiredImageToProduct)
    },
    verifyProductHasDataImage() {
      const pictureList = this.getPictureList || []
      const requireImage = this.checkIfAnyItemRequireImage()
      if(requireImage) {
        if(pictureList.length == 0) {
          this.$ShowAlert({
            variant: 'warning',
            message: this.$t('etapas.fotos.erroQTDFotos'),
          })
          return false
        } else {
          return true
        }
      } 
      return true      
    }
  }, 
  mounted() {    
    this.setCurrentStep(2)
  },   
}
</script>

<style lang="scss">
.dass-stepper-container { 
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto; 
  hr {
    margin-top: var(--space__0-5x);
    width: 100%;
  }
  .dass-actions-finish {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--space);   
    .dass-actions-finish-container {
      display: flex;
      gap: var(--space);
    }
  }
}
.subtexto {
  span svg {
    cursor: pointer !important;
    margin-left: var(--space__0-5x);
    padding: var(--space__0-25x);
    &:hover {
      background-color: #E9EBEE;
    }
  }
}  
</style>
