import Protocols from '@/services/protocols'
import store from '../..'
import * as types from './mutation-types'

export const loadProtocols = async ({ commit }, filters) => {
  const brand = store.getters['brand/getBrand'] || {}
  const mar_id = brand.id || ''
  const default_filters = {
    mar_id,
  }

  const request_body = Object.assign({}, default_filters, filters)
  const response = await Protocols.getProtocols(request_body)
  let protocols = response.calls || []
  
  commit(types.FILL_PROTOCOLS, { protocols })
  return protocols
}


export const loadProductBalanceB2C = async ({ commit }, filters) => {
  const response = await Protocols.getProductBalanceB2C(filters) 
  let productBalance = response.productBalance || [] 
  
  productBalance.sort((a, b) => {
    const tamanhoA = parseInt(a.TAM_ORDEM, 10)
    const tamanhoB = parseInt(b.TAM_ORDEM, 10)
    if (isNaN(tamanhoA)) {
      return 1
    }
    if (isNaN(tamanhoB)) {
      return -1
    }
    return tamanhoA - tamanhoB
  })

    
  return productBalance
}
