function capitalizeAllWords(phrase) { 
  if (!phrase) return false

  let words = phrase.toLowerCase().split(' ')

  words = words.map((word) => {
    return ( word.charAt(0).toUpperCase() ) + word.slice(1)
  })
  words = words.join(' ')
  
  return words
}

export default capitalizeAllWords