<template>
  <div class="dass-modal-exchange">
    <dass-dialog
      v-if="show"
      title=" " 
      size="medium"
      ref="ModalExchange"
      class="dass-dialog--exchange"
      @close="closeModal"
    >
      <template #content>            
          <div class="dass-title">
            <h3>{{ this.$t('dass.notSecondExchangeTitle') }} </h3>
          </div>
          <div v-if="!messageSLA" class="dass-subtitle">
            {{ this.$t('dass.notSecondExchange') }}
          </div>
          <div v-else class="dass-subtitle">
            {{ message }}
          </div>         
      </template>
    </dass-dialog>
  </div>
</template>

<script>

export default {
  name: 'ModalExchange',
  props: {    
    showModal: {
      type: Boolean,
      default: false
    },
    messageSLA: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },
  emits: ['closeModal'],
  computed: {
    show() {      
      return this.showModal
    }
  },
  data() {
    return {      
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },    
  }
}

</script>

<style>
.dass-modal-exchange {
  .dass-dialog--close {
      color: var(--background-color-button-primary-shopping-voucher);
      padding: 0 !important;
      height: 50px;
      width: 50px;
    }
    .dass-dialog--header {
      padding-top: 0 !important;
      padding-right: 0 !important;
    }
    .dass-dialog--control {
      border-radius: 10px !important;
    }    
    .dass-dialog--contents {
      padding-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-right: 1rem;
      margin-left: 1rem;
    }    
    .dass-subtitle {           
      padding-bottom: var(--space);
    }    
    .dass-message-refund {
        margin-bottom: var(--space__0-5x);
        margin-top: var(--space__0-5x);
        line-height: normal !important;
    }    
    .dass-button__primary {
      width: 100%;
      border-radius: var(--theme-border-radius);
      background-color: var(--background-color-button-primary-shopping-voucher) !important;
    }
    .dass-button__tertiary {
      width: 100%;
      text-decoration: underline ;
    }
    .dass-button-tertiary-newbalance {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #E5788B !important;
      }
    }
    .dass-button-primary-newbalance {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #E5788B !important;
      }
    }
    .dass-button-tertiary-umbro {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #737373 !important;
      }
    }
    .dass-button-primary-umbro {
      transition: border 0.3s ease !important;
      &:hover {
        border: 2px solid #737373 !important;
      }
    }
    .dass-button-tertiary-fila {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #758396 !important;
      }
    }
    .dass-button-primary-fila {
      transition: background-color 0.3s ease !important;
      &:hover {
        background-color: #758396 !important;
      } 
    }
  }
  .theme-umbro {
    .dass-dialog--control {
      background-color: var(--theme-color);
      color: var(--theme-white);
    }
    .dass-link {
      color: var(--theme-white) !important;
    }
    .dass-button__tertiary {
      color: var(--theme-white) !important;
      text-decoration: underline !important;
    }
    .dass-button__primary {
      background-color: var(--theme-white) !important;
      color: var(--theme-color) !important;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #758396;
      }
    }   
  }
</style>