<template>
  <div class="dass-container-view-img">
    <dass-dialog
        v-if="showModalView"
        title=" " 
        :size="sizeModal"
        ref="ModalShoppingVoucher"
        class="dass-dialog--view-img"
        @close="closeModal"
    >        
        <template #content>            
            <img v-if="imgSrc" :src="imgSrc" :style="{ transform: `scale(${zoomLevel})` }" />
            <div v-if="!imgSrc" class="dass-container-view-img-object-picture">
              <img v-if="objectPicture.file_type==='image'" :src="objectPicture.object_url" alt="" :style="{ transform: `scale(${zoomLevel})` }" />
              <object v-if="objectPicture.file_type==='file'" :data="objectPicture.object_url" :type="objectPicture.type">
                  <img  alt="" :src="requireImage(`files/file.png`)" width="158" height="158"/>
              </object>
            </div>
          </template>
          <template #actions>
            <dass-button @click="zoomIn">                
                <dass-icon
                  token="icon-action-zoom_in"
                  size="30"
                ></dass-icon>
            </dass-button>
            <dass-button @click="zoomOut">                
                <dass-icon
                  token="icon-action-zoom_out"
                  size="30"
                ></dass-icon>
            </dass-button>
          </template>
      </dass-dialog>
  </div>
</template>

<script>

export default {
  name: 'ModalViewImg',
  props: {
    imgSrc: {
      type: String,
      default: ''
    },
    sizeModal: {
      type: String,
      default: 'medium'
    },
    objectPicture: {
      type: Object,
      default: () => {}
    },
    showModal: {
      type: Boolean,
      default: false
    },
  },
  emits: ['closeModal'],
  computed: {
    showModalView() {      
      return this.showModal
    }
  },
  data() {
    return {
      zoomLevel: 1
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    zoomIn() {      
      this.zoomLevel += 0.1
      if (this.zoomLevel > 1.3) {
        this.zoomLevel = 1.3
      }
    },
    zoomOut() {
      this.zoomLevel -= 0.1
      if (this.zoomLevel < 1) {
        this.zoomLevel = 1
      }
    }
  }
}

</script>

<style>

.dass-container-view-img {
  .dass-dialog--close {
      color: var(--background-color-button-primary-shopping-voucher);
      padding: 0 !important;
      height: 50px;
      width: 50px;
    }
    .dass-dialog--header {
      padding-top: 0 !important;
      padding-right: 0 !important;
    }
    .dass-dialog--control {
      border-radius: 10px !important;
    }    
    .dass-dialog--contents {
      padding-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;   
      > img {
        width: 100%;
        height: 100%;
      }  
      .dass-container-view-img-object-picture {
        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    } 
    .dass-dialog--actions {
    padding-top: var(--space__3x) !important;
   } 
}
</style>