<template>
  <div class="dass-resumed-data">
    <div :style="!isPaymentReversal ? 'column-gap: var(--space__3x)' : ''" class="dass-resumed-data-container">
      <div class="dass-resumed-data-client">
        <h3>{{ $t('etapas.comprador.dataClient') }}</h3>
        <div>
          <div>
            <span>{{ $t('etapas.comprador.cpf') }}:</span>
            <b>{{ form_data.buyer.cpf }}</b>
          </div>
          <div>
            <span>{{ $t('etapas.comprador.nome') }}:</span>
            <b>{{ form_data.buyer.nome }}</b>
          </div>              
          <div>
            <span>{{ $t('etapas.comprador.email') }}:</span>
            <b>{{ form_data.buyer.email }}</b>
          </div>        
          <div>
            <span>{{ $t('etapas.comprador.telefone') }}:</span>
            <b>{{ form_data.buyer.telefone }}</b>
          </div>
        </div>
      </div>
      <div v-if="isPaymentReversal" class="dass-resumed-data-bank">          
        <h3>{{ $t('dass.bankData') }}</h3>
        <div>
          <div>
            <span>{{ $t('etapas.comprador.agencia') }}:</span>
            <b>{{ form_data.bank.cli_agencia }}</b>
          </div>
          <div>
            <span>{{ $t('etapas.comprador.conta') }}:</span>
            <b>{{ form_data.bank.cli_conta }}</b>
          </div>
          <div>
            <span>{{ $t('etapas.comprador.codigoBanco') }}:</span>
            <b>{{ form_data.bank.ban_codigo }}</b>
          </div>
          <div>
            <span>{{ $t('etapas.comprador.chavePix') }}:</span>
            <b>{{ this.form_data.bank.cli_chavepix }}</b>
          </div>           
        </div>
      </div>  
      <div class="dass-resumed-data-delivery" :class="{ 'is-payment-reversal': isPaymentReversal }">          
        <h3>{{ $t('etapas.comprador.addressClient') }}</h3>
        <div>
          <div>
            <span>{{ $t('etapas.comprador.cep') }}:</span>
            <b>{{ form_data.address.cep }}</b>            
          </div>
          <div>
            <span>{{ $t('etapas.comprador.endereco') }}:</span>
            <b>{{ form_data.address.endereco }}</b>
          </div>          
          <div>
            <span>{{ $t('etapas.comprador.numero') }}:</span>
            <b>{{ form_data.address.numero }}</b>
          </div>          
          <div>
            <span>{{ $t('etapas.comprador.uf') }}:</span>
            <b>{{ form_data.address.uf }}</b>
          </div>          
        </div>
        <div>
          <div>
            <span>{{ $t('etapas.comprador.bairro') }}:</span>
            <b>{{ form_data.address.bairro }}</b>
          </div>
          <div>
            <span>{{ $t('etapas.comprador.cidade') }}:</span>
            <b>{{ form_data.address.cidade }}</b>
          </div>
          <div>
            <span>{{ $t('etapas.comprador.complemento') }}:</span>
            <b>{{ form_data.address.complemento }}</b>
          </div> 
          <div class="actions">
            <dass-button            
            :class="this.isSafari ? `dass-button-tertiary-${this.brandName} button` : 'button'"
            :id="`dass-button-tertiary-close-${this.brandName}`"
            variant="tertiary"
            type="button"
            @click="editForm"
            >
            <dass-icon token="icon-content-create" size="30"></dass-icon>
            {{ $t('dass.edit') }}
          </dass-button> 
          </div>
        </div>          
      </div>
    </div>    
  </div>
</template>
<script>
import { useIsSafari } from '@/composables/useIsSafari'
export default {
  name: 'ResumedData ',
  props: {
    form_data: {
      type: Object,
      required: true
    },
    isPaymentReversal: {
      type: Boolean,
      required: true
    }
  },
  emits: ['editFormEmit'],
  setup() {
    const { isSafari, brandName } = useIsSafari()
    
    return {      
      isSafari,
      brandName
    }     
  },
  methods: {
    editForm() {      
      this.$emit('editFormEmit')
    }
  }
}
</script>

<style scoped>
.dass-resumed-data {
  width: 100%;
  height: auto;
  .dass-resumed-data-container {
    @media (max-width: 500px) {
      flex-direction: column;
    }
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;    
      gap: var(--space); 
       .dass-resumed-data-client {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: var(--space__0-75x);
        > h3 {
          color: var(--theme-success);
          width: 100%; 
          white-space: nowrap;    
          margin-bottom: 0;           
        }      
        > div { 
          flex: 1; 
          > div span {
           margin-right: var(--space__0-25x); 
          }           
        }
      }
      .dass-resumed-data-bank {           
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: var(--space__0-75x);
        > h3 {
          color: var(--theme-success);
          width: 100%;    
          margin-bottom: 0;    
        }      
        > div {  
          flex: 1;  
          > div span {
           margin-right: var(--space__0-25x); 
          }   
        }
      }
      .dass-resumed-data-delivery {           
        flex: 3;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: var(--space__0-75x);
        > h3 {
          color: var(--theme-success);
          width: 100% !important;
          margin-bottom: 0;  
        } 
           
        > div {  
          flex: 1;
          > div span {
           margin-right: var(--space__0-25x); 
          }          
        }        
      }
      .is-payment-reversal {
        @media (max-width: 1200px) {
          flex: none;
          width: 100%;     
          display: flex;              
        }
      }
      .actions {        
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;      
      }
    }  
}

</style>