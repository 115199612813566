export const formatOptionsBankToSelect = (banks, textSelectedItem) => {  
  if(!banks || banks.length == 0) return
  const optionsBanks = banks.map(item => {              
    return {
      value: item.BAN_CODIGO,
      text:  item.BAN_CODIGO  + ' - ' + item.BAN_NOME 
    }       
  })  
  if(!optionsBanks.some(item => item.text == textSelectedItem)) {
    optionsBanks.unshift({
      value: '',
      text: textSelectedItem,
      disabled: true,
      selected: true
    })      
  } 
  return optionsBanks
}