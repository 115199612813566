export function createGradientOfColor(stringColors) {  
  const colorsArray = stringColors.split(',')
    
  if (colorsArray.length === 1) {    
    return `background: ${colorsArray[0]}`
  }
    
  const firstColor = colorsArray[0]
  const restColors = colorsArray.slice(1)  
 
  const gradiente = [
    `${firstColor} 60%`, // dá mais destaque à primeira cor
    ...restColors.map(cor => `${cor} ${100 / (colorsArray.length - 1)}%`)
  ].join(', ')
    
  return `background: linear-gradient(to right, ${gradiente});`
}