<template>
  <customer-view-template>
    <div class="home">
      <order-list></order-list>
      <div class="container-btn-produtos">
        <transition name="slide">
          <dass-button
            v-if="this.isMobile"
            :class="produtosForaLojaOnline ? 'dass-btn-minimize' : 'dass-btn-minimize-bottom'"
            variant="primary"
            type="button"
            @click="produtosForaLojaOnline = !produtosForaLojaOnline"
          >
          <dass-icon token="icon-navigation-expand_less" size="20"  v-if="produtosForaLojaOnline"/>
          <dass-icon token="icon-navigation-expand_more" size="20" v-else/>
          </dass-button>
        </transition>
        <transition name="slide">
          <div class="produtos-fora-loja-online" v-if="produtosForaLojaOnline">
            <div class="container">
              <div>
                <b> {{ $t('home.produtosFisicos') }} </b>
              </div>
              <div>
                <dass-button
                  class="button-acesso-rapido"
                  variant="secondary"
                  type="button"
                  @click="
                    () => {
                      $router.push({
                        path: '/produtos-adquiridos-fora-da-loja-online/produto',
                      })
                    }
                  "
                >
                  {{ $t('dass.cliqueAqui') }}
                </dass-button>
              </div>          
            </div>
          </div>
        </transition>
      </div>
    </div>
    <sidebar />
  </customer-view-template>
</template>
<script>
import CustomerViewTemplate from '../components/templates/CustomerView'
import OrderList from '../components/organisms/home/OrderList'
import Sidebar from '../components/organisms/home/sidebar/Sidebar'
import { useMobile } from '../composables/useMobile'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Home',
  components: { CustomerViewTemplate, OrderList, Sidebar },
  computed: {
    ...mapGetters('orders', {
      orders: 'getOrders'      
    }),    
  },
  setup() {
    const { isMobile } = useMobile()    
    return {
      isMobile,
    }
  },
  data() {
    return {
      produtosForaLojaOnline: true,
    }
  },  
  methods: {
    ...mapActions('orders', ['toggleSidebar']),    
  },
}
</script>

<style lang="scss">
.home {
  text-align: left;
  padding-bottom: 65px;
  .container-btn-produtos {
    position: relative;
    .dass-btn-minimize {
      position: fixed;
      bottom: 65px;
      right: 0; 
      padding: 0;
      &:focus {        
        outline: none !important;
      }     
    }
    .dass-btn-minimize-bottom {
      position: fixed;
      bottom: 0;
      right: 0; 
      padding: 0;
      &:focus {        
        outline: none !important;
      }     
    }
    .produtos-fora-loja-online {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--theme-white);
      z-index: 11; 
      width: 100%;
      height: 65px;
      display: flex;
      justify-content: center;
      border: var(--theme-border);
      border-color: var(--theme-gray_light);
      .container {
        width: 80%;
        display: flex;
        align-items: center;
        > div {
          display: flex;
          align-items: center;
          &:first-of-type {
            padding-right: var(--space);
          }
          padding: var(--space__0-5x);
          button {
            padding: initial;

            width: 100%;
            height: 100%;
            padding: var(--space__0-5x) var(--space__2x);
          }
        }
        @media (max-width: 1024px) {
          padding: var(--space);

          width: 100%;
          > div {
            width: 50%;
            line-height: 1rem;
            b {
              color: var(--theme-gray);
            }
            button {
              padding: var(--space__0-75x) var(--space);
            }
          }
        }
        @media (max-width: 700px) {
          padding: 0.5rem;
        }
      }
    }
  }
  .slide-enter-active, .slide-leave-active {
    transition: all 2s ease;   
  }
  .slide-enter-from {
    opacity: 0;
    transform: translateY(50px);
  }  
  .slide-leave-to {
    opacity: 0;
    transform: translateY(50px);
  }   
}

.theme-umbro {
  .produtos-fora-loja-online {
    border: none !important;
    background-color: var(--theme-color) !important;
    b {
      color: var(--theme-white);
    }
  }
}
.theme-umbro .t-customer-view .dass-header .dass-nav li a {
  color: var(--theme-gray) !important;
}
</style>