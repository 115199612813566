export const parsePhoneNumber = function (phoneNumber) {  
  if(!phoneNumber) return 
  let phoneCleaned = phoneNumber.replace(/\D/g, '')
  let ddd = '' 
  let phone = phoneCleaned
  let ddi = ''
 
  if(phone.length > 11) {
    ddi = phone.slice(0, 2)
    phone = phone.slice(2, phone.length)    
  }
  
  if(phone.length == 10 || phone.length == 11) {
    ddd = phone.slice(0, 2)
    phone = phone.slice(2, phone.length)
  }
 
  return {
    ddd,
    ddi,
    phone
  }
    
}