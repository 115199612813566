<template>
  <div v-if="historic.length > 0" class="dass-container-history">
    <div class="dass-historic">
    <div   class="timeline">
      <ol class="activity-feed">
        <li v-for="(history, index ) in historic" v-bind:key="history.PHS_ID"  class="feed-item">
          <div class="dass-status-history" @click="showHistory(index, history.SIT_ID)">            
            <span :class="`color-sit-${history.SIT_ID} status`">
              {{ $t(`situacoes.${history.SIT_ID}`) }}
            </span>
          </div>
          <Transition name="slide-fade">
            <div v-if="showHistoryList[`${history.SIT_ID}_${index}`]" >
              <div class="card">
                <div class="card-header">
                  <div>
                    <h3>{{ $t('protocolo.status') }}:</h3>
                    <b>{{ history.SIT_NOME }}</b>
                  </div>
                  <div>
                    <h3>{{ $t('dass.dtAtendimento') }}:</h3>
                    <b class="date">{{ $filters.toDateBRL(history.PHS_DATA) }} - {{ $filters.toHourBRL(history.PHS_DATA) }}</b>
                  </div>
                  <div>
                    <h3>{{ $t('dass.observacoes') }}:</h3>
                  </div> 
                </div>
                <div class="card-body">
                  <div class="dass-obs-msg">
                    <span v-html="history.MSG_DESC"></span>
                  </div> 
                </div>
                <div class="card-footer">
                  <b @click="showHistory(index, history.SIT_ID)" class="close">{{ $t('dass.close') }}</b>
                </div>
              </div>
            </div>
          </Transition>
        </li>
        </ol>
      </div>
    </div>
  </div>
  <div v-else>{{ $t('erro.semDados') }}</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'history',  
  data() {
    return {
      showHistoryList: [],
    }
  },
  computed: {
    ...mapGetters('protocol_detail', { historic: 'getHistory' }),
    ...mapGetters('protocol_detail', { protocol: 'getProtocol' }),
  },
  methods: {
    showHistory: function (index, sitId) {      
      const key = `${sitId}_${index}`
      this.showHistoryList[key] = !this.showHistoryList[key]
    },
    currentStatusShow: function () {
      const statusProtocol = this.protocol.SIT_ID      
      const currentIndex = this.historic.findLastIndex((history) => history.SIT_ID === statusProtocol)
     
      if (currentIndex !== -1) {
        this.showHistory(currentIndex, this.historic[currentIndex].SIT_ID)
      }
    },
  },  
  updated() {
    this.$nextTick(() => {
      this.currentStatusShow()
    })
  },
}
</script>

<style lang="scss">
.dass-container-history {
  .dass-historic {      
    padding-left: var(--space__2x);
    @media (max-width: 1024px) {
      padding-left: var(--space);
    }
  }
  .dass-status-history {
    font-size: 1.2rem;
    .status {
      font-weight: bold;
      transition: transform 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        padding: var(--space__0-5x);
        border-radius: var(--theme-border-radius);        
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        transform: scale(1.2);
        animation: animt 1s infinite;        
      } 
      @keyframes animt {
        0% {
          transform: scale(1);
          opacity: 1;
          box-shadow: 0 0 10px rgba(156, 155, 155, 0.2);
        }
        20% {
          transform: scale(1.05);
          opacity: 0.8;
          box-shadow: 0 0 20px rgba(116, 115, 115, 0.4);
        }
        40% {
          transform: scale(1.1);
          opacity: 0.6;
          box-shadow: 0 0 30px rgba(68, 68, 68, 0.6);
        }
        60% {
          transform: scale(1.05);
          opacity: 0.8;
          box-shadow: 0 0 20px rgba(156, 155, 155, 0.2);
        }
        80% {
          transform: scale(1.1);
          opacity: 0.6;
          box-shadow: 0 0 30px rgba(68, 68, 68, 0.6);
        }
        100% {
          transform: scale(1);
          opacity: 1;
          box-shadow: 0 0 10px rgba(156, 155, 155, 0.2);
        }
      }      
    }    
  }
  .slide-fade-enter-active {
    transition: all 0.5s ease-out;
  }
  .slide-fade-leave-active {
    transition: all 0.5s ease-in;
  }
  .slide-fade-enter {
    transform: translateY(50px);
    opacity: 0;
  }
  .slide-fade-leave-to {
    transform: translateY(-50px);
    opacity: 0;
  } 
  .dass-obs-msg {
    width: 100%;   
    max-height: 250px; 
    overflow-y: scroll;
    text-align: center;    
    > span {
      font-size: 1.2rem;
    }
  }

  .timeline {
    .activity-feed {
      padding: 15px;
      list-style: none;
    }

    .activity-feed .feed-item {
      position: relative;
      padding-bottom: var(--space__2x);
      padding-left: var(--space__3x);
      padding-top: var(--space__0-25x);
      border-left: var(--space__0-25x) solid #D4D6D8;
      @media(max-width: 700px) {
        padding-left: var(--space__1-5x);
      }
    }

    .activity-feed .feed-item:last-child {
      border-color: transparent;
    }

    .activity-feed .feed-item::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -18px;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      background: var(--color-neutral__07);
      border: 1px solid #47cf73;
    }
    .card {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: var(--theme-border-radius);      
      margin-bottom: var(--space__2x);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
    }

    .card-header {
      background-color: var(--color-neutral__07);
      padding: var(--space__1-5x);      
      display: flex;      
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;
      flex-wrap: wrap;  
      h3 {
        color: var(--theme-success);
        margin-bottom: 0;
        padding-bottom: 0;
      }
      b {
        font-size: 1.2rem;
      }
      > div {
        flex: 1 1 50%;
        min-width: 250px;
        text-align: center;
        
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: var(--space__1-5x);
      width: 100%;
      
    }

    .card-footer {
      background-color: var(--color-neutral__07);
      padding: var(--space);
      b {       
        font-size: 1.2rem;       
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    
  }

}
</style>
