<template>    
      
      <div class="product-pictures">
        <form action="" @submit="formSubmit">
          <div class="form-inline">            
            <div>
              <h3>{{ $t('etapas.fotos.titulo') }}</h3>
              <dass-send-file
                @input="attachPicture"                           
                :multiple="true"
              >                
                <template v-slot:label-instructions>                  
                  {{ $t('etapas.fotos.arraste') }}
                </template>
                <template v-slot:label-separator>{{
                  $t('etapas.fotos.ou')
                }}</template>
                <template  v-slot:upload-button>               
                  {{ $t('etapas.fotos.anexar') }}
                  <dass-icon token="icon-file-attachment" size="25" />
                </template>
              </dass-send-file>
            </div>            
          </div> 
        </form>
      </div>    
  </template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { useIsSafari } from '@/composables/useIsSafari'
const refund = 'Estorno'
  
export default {
  name: 'ProductUploadImage',  
  computed: {
    ...mapGetters('brand', { brand: 'getBrand' }),
    ...mapGetters('create_protocol', {
      dados_item: 'getDadosItens',
      getPictureList: 'getPictureList'
    }),
  },
  setup() {
    const { isSafari, brandName } = useIsSafari()
    
    return {      
      isSafari,
      brandName
    }     
  },
  watch: {
    picture_list: {
      handler(newValue, oldValue) {        
        this.setPictureList(newValue)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('create_protocol', ['setCurrentStep', 'setPictureList']),
    ...mapActions('toasts', ['showToast']),    
    formSubmit: function (e) {
      e.preventDefault()
      let pictureList = this.picture_list
      if (pictureList.length == 0) {
        return this.showToast({
          variant: 'warning',
          message: this.$t('etapas.fotos.erroQTDFotos'),
        })
      }
      this.setPictureList(pictureList)
      return this.next()
    },
    attachPicture: function (e) {      
      if (e.hasOwnProperty('isTrusted')) return
      Array.from(e).forEach((picture) => {
        if (this.getPictureList.length >= this.picture_qtd) return
        let fileType = ''
        if(['image'].includes(picture.type.substring(0, picture.type.indexOf('/')) ))
          fileType='image'
        else
          fileType='file'
  
        let temp = {
          original_file: picture,
          object_url: URL.createObjectURL(picture),
          file_type: fileType,
          type: picture.type
        }
        this.setPictureList([...this.getPictureList, temp])        
        
      })
    },
    
    next: function () { 
      this.$router.push({
        path: '/produtos-adquiridos-fora-da-loja-online/confirmacao',
      })
    },
    cancel: function () {
      this.$router.go(-1)
    },
    setCssForSafari() {
      const buttonFile = document.querySelector('.dass-send-file--upload-button')    
      switch (this.brandName) {
      case 'fila':
        buttonFile.classList.add('dass-button-primary-fila')
        break
      case 'umbro':
        buttonFile.classList.add('dass-button-primary-umbro')  
        break
      case 'newbalance':
        buttonFile.classList.add('dass-button-primary-newbalance')
        break
      default:
        break
      }
    }
  },  
  mounted: function () {
    if(this.isSafari) {
      this.setCssForSafari()  
    }    
    if(this.getPictureList.length > 0) {
      this.picture_list = this.getPictureList
    }
  },
  data: () => ({
    form_data: {},
    picture_list: [],
    picture_qtd: 5,    
  }),
}
</script>
  
  <style lang="scss" scoped>
  .product-pictures {
    text-align: left;  
    form {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .form-inline {
        width: 100%;
        > div > h3 {
          display: flex;
          justify-content: center;
        }
      } 
      .actions {
        > button:first-of-type {
          margin-right: var(--space);
        }
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
      }
    }
    #dass-button-tertiary-close-umbro {
      color: var(--theme-color) !important;    
    }
  }
  </style>  